import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'itemQty',
    label: i18n('entities.lineBuildItem.fields.itemQty'),
    schema: schemas.integer(
      i18n('entities.lineBuildItem.fields.itemQty'),
      {},
    ),
  },
  {
    name: 'itemUnit',
    label: i18n('entities.lineBuildItem.fields.itemUnit'),
    schema: schemas.string(
      i18n('entities.lineBuildItem.fields.itemUnit'),
      {},
    ),
  },
  {
    name: 'ingredient',
    label: i18n('entities.lineBuildItem.fields.ingredient'),
    schema: schemas.relationToOne(
      i18n('entities.lineBuildItem.fields.ingredient'),
      {},
    ),
  },
  {
    name: 'batchRecipe',
    label: i18n(
      'entities.lineBuildItem.fields.batchRecipe',
    ),
    schema: schemas.relationToOne(
      i18n('entities.lineBuildItem.fields.batchRecipe'),
      {},
    ),
  },
  {
    name: 'preferredBrand',
    label: i18n(
      'entities.lineBuildItem.fields.preferredBrand',
    ),
    schema: schemas.string(
      i18n('entities.lineBuildItem.fields.preferredBrand'),
      {},
    ),
  },
  {
    name: 'alternativeBrand',
    label: i18n(
      'entities.lineBuildItem.fields.alternativeBrand',
    ),
    schema: schemas.string(
      i18n(
        'entities.lineBuildItem.fields.alternativeBrand',
      ),
      {},
    ),
  },
  {
    name: 'prepNote',
    label: i18n('entities.lineBuildItem.fields.prepNote'),
    schema: schemas.string(
      i18n('entities.lineBuildItem.fields.prepNote'),
      {},
    ),
  },
];
