import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.brandMenu.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.brandMenu.fields.name'),
  },
  {
    name: 'description',
    label: i18n('entities.brandMenu.fields.description'),
  },
  {
    name: 'category',
    label: i18n('entities.brandMenu.fields.category'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'customerRequests',
    label: i18n('entities.brandMenu.fields.customerRequests'),
  },
  {
    name: 'price',
    label: i18n('entities.brandMenu.fields.price'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'vatPercentage',
    label: i18n('entities.brandMenu.fields.vatPercentage'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'isVAT',
    label: i18n('entities.brandMenu.fields.isVAT'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'status',
    label: i18n('entities.brandMenu.fields.status'),
  },
  {
    name: 'image',
    label: i18n('entities.brandMenu.fields.image'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.brandMenu.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.brandMenu.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
