import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/lineBuildAddon/importer/lineBuildAddonImporterSelectors';
import LineBuildAddonService from 'src/modules/lineBuildAddon/lineBuildAddonService';
import fields from 'src/modules/lineBuildAddon/importer/lineBuildAddonImporterFields';
import { i18n } from 'src/i18n';

const lineBuildAddonImporterActions = importerActions(
  'LINEBUILDADDON_IMPORTER',
  selectors,
  LineBuildAddonService.import,
  fields,
  i18n('entities.lineBuildAddon.importer.fileName'),
);

export default lineBuildAddonImporterActions;
