import list from 'src/modules/lineBuildExtra/list/lineBuildExtraListReducers';
import form from 'src/modules/lineBuildExtra/form/lineBuildExtraFormReducers';
import view from 'src/modules/lineBuildExtra/view/lineBuildExtraViewReducers';
import destroy from 'src/modules/lineBuildExtra/destroy/lineBuildExtraDestroyReducers';
import importerReducer from 'src/modules/lineBuildExtra/importer/lineBuildExtraImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
