import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/cutting/importer/cuttingImporterSelectors';
import CuttingService from 'src/modules/cutting/cuttingService';
import fields from 'src/modules/cutting/importer/cuttingImporterFields';
import { i18n } from 'src/i18n';

const cuttingImporterActions = importerActions(
  'CUTTING_IMPORTER',
  selectors,
  CuttingService.import,
  fields,
  i18n('entities.cutting.importer.fileName'),
);

export default cuttingImporterActions;
