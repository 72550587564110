import list from 'src/modules/combo/list/comboListReducers';
import form from 'src/modules/combo/form/comboFormReducers';
import view from 'src/modules/combo/view/comboViewReducers';
import destroy from 'src/modules/combo/destroy/comboDestroyReducers';
import importerReducer from 'src/modules/combo/importer/comboImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
