import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/draftLineBuildItem/importer/lineBuildItemImporterSelectors';
import LineBuildItemService from 'src/modules/draftLineBuildItem/lineBuildItemService';
import fields from 'src/modules/draftLineBuildItem/importer/lineBuildItemImporterFields';
import { i18n } from 'src/i18n';

const lineBuildItemImporterActions = importerActions(
  'LINEBUILDITEM_IMPORTER',
  selectors,
  LineBuildItemService.import,
  fields,
  i18n('entities.lineBuildItem.importer.fileName'),
);

export default lineBuildItemImporterActions;
