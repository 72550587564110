import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/combo/importer/comboImporterSelectors';
import ComboService from 'src/modules/combo/comboService';
import fields from 'src/modules/combo/importer/comboImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'COMBO_IMPORTER',
  selectors,
  ComboService.import,
  fields,
  i18n('entities.combo.importer.fileName'),
);
