import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import moment from 'moment';

export default [
  {
    name: 'sku',
    label: i18n('entities.purchaseOrder.fields.sku'),
    schema: schemas.string(
      i18n('entities.purchaseOrder.fields.sku'),
      {},
    ),
  },
  {
    name: 'itemName',
    label: i18n('entities.purchaseOrder.fields.itemName'),
    schema: schemas.string(
      i18n('entities.purchaseOrder.fields.itemName'),
      {},
    ),
  },
  {
    name: 'qty',
    label: i18n('entities.purchaseOrder.fields.qty'),
    schema: schemas.decimal(
      i18n('entities.purchaseOrder.fields.qty'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.purchaseOrder.fields.unit'),
    schema: schemas.string(
      i18n('entities.purchaseOrder.fields.unit'),
      {},
    ),
  },
  {
    name: 'cost',
    label: i18n('entities.purchaseOrder.fields.cost'),
    schema: schemas.decimal(
      i18n('entities.purchaseOrder.fields.cost'),
      {},
    ),
  },
  {
    name: 'vATPercentage',
    label: i18n(
      'entities.purchaseOrder.fields.vATPercentage',
    ),
    schema: schemas.decimal(
      i18n('entities.purchaseOrder.fields.vATPercentage'),
      {},
    ),
  },
  {
    name: 'vat',
    label: i18n('entities.purchaseOrder.fields.vat'),
    schema: schemas.decimal(
      i18n('entities.purchaseOrder.fields.vat'),
      {},
    ),
  },
  {
    name: 'total',
    label: i18n('entities.purchaseOrder.fields.total'),
    schema: schemas.decimal(
      i18n('entities.purchaseOrder.fields.total'),
      {},
    ),
  },
  {
    name: 'date',
    label: i18n('entities.purchaseOrder.fields.date'),
    schema: schemas.date(
      i18n('entities.purchaseOrder.fields.date'),
      {},
    ),
    render: (value) =>
      value && value instanceof Date
        ? moment(value).format('YYYY-MM-DD')
        : value,
  },
];
