import RecipeItemService from 'src/modules/draftRecipeItem/recipeItemService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'RECIPEITEM_VIEW';

const recipeItemViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: recipeItemViewActions.FIND_STARTED,
      });

      const record = await RecipeItemService.find(id);

      dispatch({
        type: recipeItemViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: recipeItemViewActions.FIND_ERROR,
      });

      getHistory().push('/draft-recipe-item');
    }
  },
};

export default recipeItemViewActions;
