import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import supplyHistoryEnumerators from 'src/modules/supplyHistory/supplyHistoryEnumerators';
import moment from 'moment';

export default [
  {
    name: 'supplier',
    label: i18n('entities.supplyHistory.fields.supplier'),
    schema: schemas.relationToOne(
      i18n('entities.supplyHistory.fields.supplier'),
      {},
    ),
  },
  {
    name: 'ingredient',
    label: i18n('entities.supplyHistory.fields.ingredient'),
    schema: schemas.relationToOne(
      i18n('entities.supplyHistory.fields.ingredient'),
      {},
    ),
  },
  {
    name: 'purchaseDate',
    label: i18n(
      'entities.supplyHistory.fields.purchaseDate',
    ),
    schema: schemas.datetime(
      i18n('entities.supplyHistory.fields.purchaseDate'),
      {},
    ),
    render: (value) =>
      value && value instanceof Date
        ? moment(value).format('YYYY-MM-DD HH:mm')
        : value,
  },
  {
    name: 'cost',
    label: i18n('entities.supplyHistory.fields.cost'),
    schema: schemas.decimal(
      i18n('entities.supplyHistory.fields.cost'),
      {},
    ),
  },
  {
    name: 'section',
    label: i18n('entities.supplyHistory.fields.section'),
    schema: schemas.relationToOne(
      i18n('entities.supplyHistory.fields.section'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.supplyHistory.fields.unit'),
    schema: schemas.relationToOne(
      i18n('entities.supplyHistory.fields.unit'),
      {},
    ),
  },
  {
    name: 'unitsPerPack',
    label: i18n(
      'entities.supplyHistory.fields.unitsPerPack',
    ),
    schema: schemas.integer(
      i18n('entities.supplyHistory.fields.unitsPerPack'),
      {},
    ),
  },
  {
    name: 'vat',
    label: i18n('entities.supplyHistory.fields.vat'),
    schema: schemas.enumerator(
      i18n('entities.supplyHistory.fields.vat'),
      {
        options: supplyHistoryEnumerators.vat,
      },
    ),
  },
  {
    name: 'amountVAT',
    label: i18n('entities.supplyHistory.fields.amountVAT'),
    schema: schemas.integer(
      i18n('entities.supplyHistory.fields.amountVAT'),
      {},
    ),
  },
  {
    name: 'pricePerUnit',
    label: i18n(
      'entities.supplyHistory.fields.pricePerUnit',
    ),
    schema: schemas.integer(
      i18n('entities.supplyHistory.fields.pricePerUnit'),
      {},
    ),
  },
  {
    name: 'lab',
    label: i18n('entities.supplyHistory.fields.lab'),
    schema: schemas.relationToOne(
      i18n('entities.supplyHistory.fields.lab'),
      {},
    ),
  },
  {
    name: 'paymentMethod',
    label: i18n(
      'entities.supplyHistory.fields.paymentMethod',
    ),
    schema: schemas.string(
      i18n('entities.supplyHistory.fields.paymentMethod'),
      {},
    ),
  },
  {
    name: 'invoiceDate',
    label: i18n(
      'entities.supplyHistory.fields.invoiceDate',
    ),
    schema: schemas.date(
      i18n('entities.supplyHistory.fields.invoiceDate'),
      {},
    ),
    render: (value) =>
      value && value instanceof Date
        ? moment(value).format('YYYY-MM-DD')
        : value,
  },
  {
    name: 'invoiceNo',
    label: i18n('entities.supplyHistory.fields.invoiceNo'),
    schema: schemas.integer(
      i18n('entities.supplyHistory.fields.invoiceNo'),
      {},
    ),
  },
  {
    name: 'receivingNo',
    label: i18n(
      'entities.supplyHistory.fields.receivingNo',
    ),
    schema: schemas.integer(
      i18n('entities.supplyHistory.fields.receivingNo'),
      {},
    ),
  },
  {
    name: 'attachment',
    label: i18n('entities.supplyHistory.fields.attachment'),
    schema: schemas.files(
      i18n('entities.supplyHistory.fields.attachment'),
      {},
    ),
  },
  {
    name: 'qtyPurchased',
    label: i18n(
      'entities.supplyHistory.fields.qtyPurchased',
    ),
    schema: schemas.decimal(
      i18n('entities.supplyHistory.fields.qtyPurchased'),
      {},
    ),
  },
];
