import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/brandMenu/importer/brandMenuImporterSelectors';
import BrandMenuService from 'src/modules/brandMenu/brandMenuService';
import fields from 'src/modules/brandMenu/importer/brandMenuImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'BRANDMENU_IMPORTER',
  selectors,
  BrandMenuService.import,
  fields,
  i18n('entities.brandMenu.importer.fileName'),
);
