import Importer from 'src/modules/shared/importer/importer';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import chunk from 'lodash/chunk';
import md5 from 'md5';
import {
  getBpCodes,
  getCategories,
  getMarketBrands,
  getStorageTypes,
  getUnits,
} from 'src/view/shared/importer/importerHelpers';
import IngredientService from 'src/modules/ingredient/ingredientService';
import RecipeItemService from 'src/modules/recipeItem/recipeItemService';
import LineBuildService from 'src/modules/lineBuild/lineBuildService';
import MenuCategoryService from 'src/modules/menuCategory/menuCategoryService';
import ItemService from 'src/modules/item/itemService';
import LineBuildItemService from 'src/modules/lineBuildItem/lineBuildItemService';
import {
  checkIngredientImporter,
  callbackFn,
  ingredientsImporterFields,
  formatImportableRowForIngredientsCosts,
  formatImportableRowForBPIngredients,
  formatImportableRowForBatches,
  formatImportableRowForIngredients,
  formatImportableRowForLB,
} from './helpers';

async function importRow(
  dispatch,
  actions,
  importer,
  importFn,
  row,
  importFields,
  data: any = {},
) {
  try {
    let importableRow = await importer.castForImport(row);
    const isIngredientImporter = checkIngredientImporter(
      importableRow,
      ingredientsImporterFields,
    );
    const isBPIngredientImporter = checkIngredientImporter(
      importableRow,
      ['id', 'name', 'sku', 'arabicName', 'storageType'],
    );

    const {
      unitsIds,
      categoriesIds,
      bpCodesIds,
      marketBrandsIds,
      storageTypesIds,
      ingredients,
      batches,
      lineBuilds,
      menuCategories,
    } = data;

    let isFromBPIngredientImporter =
      window?.location?.href?.includes(
        '?bpIngredient=true',
      );
    let isFromBatchesImporter =
      window?.location?.href?.includes(
        '/recipe-item/importer',
      );

    let isFromLineBuildImporter =
      window?.location?.href?.includes(
        '/line-build/importer',
      );
    let isFromIngredientsCostsImporter =
      window?.location?.href?.includes(
        '?ingredientCostImporter=true',
      );

    if (!!isFromIngredientsCostsImporter) {
      importableRow = {
        id: importableRow['id'],
        name: importableRow['arabicName'],
        marketBrand: importableRow['sku'],
        costPurchaseUnit: importableRow['storageType'],
        cost: importableRow['category'],
        sku: importableRow['name'],
        purchaseUnit: importableRow['storageType'],
      };

      let ingredient = await IngredientService?.find(
        importableRow['id'],
      );
      importableRow =
        formatImportableRowForIngredientsCosts(
          ingredient,
          importableRow,
          unitsIds,
          marketBrandsIds,
        );

      await IngredientService.update(
        importableRow['id'],
        importableRow,
      );

      dispatch({
        type: actions.IMPORT_BATCH_SUCCESS,
        payload: {
          line: row._line,
        },
      });
      return;
    }

    if (
      !!isBPIngredientImporter &&
      !!isFromBPIngredientImporter
    ) {
      let ingredient = await IngredientService.find(
        importableRow['id'],
      );
      importableRow = formatImportableRowForBPIngredients(
        ingredient,
        importableRow,
      );
      await IngredientService.update(
        importableRow['id'],
        importableRow,
      );

      dispatch({
        type: actions.IMPORT_BATCH_SUCCESS,
        payload: {
          line: row._line,
        },
      });
      return;
    }

    if (!!importableRow['recipeName']) {
      importableRow = formatImportableRowForBatches(
        importableRow,
        storageTypesIds,
        categoriesIds,
        unitsIds,
        bpCodesIds,
        ingredients,
        batches,
      );
    }

    if (isIngredientImporter) {
      importableRow = formatImportableRowForIngredients(
        importableRow,
        bpCodesIds,
        storageTypesIds,
        unitsIds,
        categoriesIds,
        marketBrandsIds,
      );
    }

    if (
      !!ingredients &&
      !!batches &&
      !!importableRow['menuItemName']
    ) {
      importableRow = formatImportableRowForLB(
        importableRow,
        menuCategories,
        ingredients,
        batches,
      );
    }

    const importHash = md5(JSON.stringify(importableRow));

    if (isIngredientImporter) {
      if (!importableRow['id']) {
        await importFn(importableRow, importHash);
      } else {
        await IngredientService.update(
          importableRow['id'],
          importableRow,
        );
      }
    } else {
      if (!importableRow['id']) {
        if (isFromBatchesImporter) {
          await RecipeItemService.create(importableRow);
        } else if (isFromLineBuildImporter) {
          await LineBuildService.create(importableRow);
        } else {
          await importFn(importableRow, importHash);
        }
      } else {
        if (isFromBatchesImporter) {
          let originalBatch = await RecipeItemService?.find(
            importableRow['id'],
          );
          let originalBatchItems = originalBatch?.item;
          let updatedBatchItems = importableRow?.items;

          for (
            let i = 0;
            i < updatedBatchItems?.length;
            i++
          ) {
            let res = await ItemService?.update(
              originalBatchItems[i]?.id,
              {
                ...updatedBatchItems[i],
                nameId: updatedBatchItems[i]?.name,
                batchRecipeId:
                  updatedBatchItems[i]?.batchRecipe,
              },
            );
            updatedBatchItems[i] = res;
          }

          await RecipeItemService?.update(
            importableRow['id'],
            {
              ...importableRow,
              items: updatedBatchItems?.map(
                (row) => row?.id,
              ),
            },
          );
        } else if (isFromLineBuildImporter) {
          let originalLB = await LineBuildService?.find(
            importableRow['id'],
          );
          let originalLBItems = originalLB?.items;
          let updatedLBItems = importableRow?.items;

          for (let i = 0; i < updatedLBItems?.length; i++) {
            let res = await LineBuildItemService?.update(
              originalLBItems[i]?.id,
              {
                ...updatedLBItems[i],
                ingredientId: updatedLBItems[i]?.ingredient,
                batchRecipeId:
                  updatedLBItems[i]?.batchRecipe,
              },
            );
            updatedLBItems[i] = res;
          }
          await LineBuildService?.update(
            importableRow['id'],
            {
              ...importableRow,
              items: updatedLBItems?.map((row) => row?.id),
            },
          );
        } else {
          await importFn(importableRow, importHash);
        }
      }
    }

    dispatch({
      type: actions.IMPORT_BATCH_SUCCESS,
      payload: {
        line: row._line,
      },
    });
  } catch (error) {
    dispatch({
      type: actions.IMPORT_BATCH_ERROR,
      payload: {
        line: row._line,
        errorMessage: Errors.selectMessage(error),
      },
    });
  }
}

export default (
  prefix,
  selectors,
  importFn,
  importFields,
  templateFileName,
  batchSize = 10,
) => {
  const actions = {
    RESETED: `${prefix}_RESETED`,

    FILE_READ_ERROR: `${prefix}_FILE_READ_ERROR`,
    FILE_READ_SUCCESS: `${prefix}_FILE_READ_SUCCESS`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

    IMPORT_STARTED: `${prefix}_IMPORT_STARTED`,
    IMPORT_ERROR: `${prefix}_IMPORT_ERROR`,
    IMPORT_PAUSED: `${prefix}_IMPORT_PAUSED`,
    IMPORT_SUCCESS: `${prefix}_IMPORT_SUCCESS`,

    IMPORT_BATCH_ERROR: `${prefix}_IMPORT_BATCH_ERROR`,
    IMPORT_BATCH_SUCCESS: `${prefix}_IMPORT_BATCH_SUCCESS`,

    doChangePagination: (pagination) => ({
      type: actions.PAGINATION_CHANGED,
      payload: pagination,
    }),

    doChangeSort:
      (rows, sorter) => async (dispatch, getState) => {
        const { field, order } = sorter;

        let sortFn = (a, b) =>
          (String(a[field]) || '').localeCompare(
            String(b[field]) || '',
          );

        if (field === '_line') {
          sortFn = (a, b) => a._line - b._line;
        }

        if (field === '_status') {
          sortFn = (a, b) =>
            (a._status || '').localeCompare(
              b._status || '',
            );
        }

        let sortedRows = [...rows].sort(sortFn);

        if (order === 'desc') {
          sortedRows = sortedRows.reverse();
        }

        dispatch({
          type: actions.SORTER_CHANGED,
          payload: {
            sortedRows,
            sorter,
          },
        });
      },

    doReset: () => {
      return {
        type: actions.RESETED,
      };
    },

    doPause: () => {
      return {
        type: actions.IMPORT_PAUSED,
      };
    },

    doImport: () => async (dispatch, getState) => {
      try {
        dispatch({
          type: actions.IMPORT_STARTED,
        });

        const pendingRows = selectors.selectPendingRows(
          getState(),
        );

        const importer = new Importer(importFields);

        const pendingBatches = chunk(
          pendingRows,
          batchSize,
        );

        const unitsIds = await getUnits(callbackFn);
        const categoriesIds = await getCategories(
          callbackFn,
        );
        const bpCodesIds = await getBpCodes(callbackFn);
        const marketBrandsIds = await getMarketBrands(
          callbackFn,
        );
        const storageTypesIds = await getStorageTypes(
          callbackFn,
        );

        let ingredients;
        let batches;
        let lineBuilds;
        let menuCategories;

        if (importFields[5]?.name === 'recipeName') {
          ingredients = await IngredientService.list(
            {},
            null,
            null,
            null,
          );
          batches = await RecipeItemService.list(
            {},
            null,
            null,
            null,
          );
        }

        if (importFields[1]?.name === 'menuItemName') {
          ingredients = await IngredientService.list(
            {},
            null,
            null,
            null,
          );
          batches = await RecipeItemService.list(
            {},
            null,
            null,
            null,
          );
          lineBuilds = await LineBuildService.list(
            {},
            null,
            null,
            null,
          );
          menuCategories = await MenuCategoryService.list(
            {},
            null,
            null,
            null,
          );
        }

        for (let batch of pendingBatches) {
          const paused = !selectors.selectImporting(
            getState(),
          );

          if (paused) {
            return;
          }

          for (let row of batch) {
            await importRow(
              dispatch,
              actions,
              importer,
              importFn,
              row,
              importFields,
              {
                unitsIds,
                categoriesIds,
                bpCodesIds,
                marketBrandsIds,
                storageTypesIds,
                ingredients: ingredients?.rows,
                batches: batches?.rows,
                lineBuilds: lineBuilds?.rows,
                menuCategories: menuCategories?.rows,
              },
            );
          }

          // await Promise.all(
          //   batch.map((row) =>
          //     importRow(
          //       dispatch,
          //       actions,
          //       importer,
          //       importFn,
          //       row,
          //     ),
          //   ),
          // );
        }

        dispatch({
          type: actions.IMPORT_SUCCESS,
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: actions.IMPORT_ERROR,
        });
      }
    },

    doDownloadTemplate: () => async (dispatch) => {
      const importer = new Importer(importFields);
      importer.downloadTemplate(templateFileName);
    },

    doReadFile: (file) => async (dispatch) => {
      try {
        const importer = new Importer(importFields);

        let rawData = await importer.convertExcelFileToJson(
          file,
        );

        if (!rawData || !rawData.length) {
          throw new Error(
            i18n('importer.errors.invalidFileEmpty'),
          );
        }

        rawData = await Promise.all(
          rawData.map(async (row, index) => {
            return await importer.castForDisplay(
              row,
              index,
            );
          }),
        );

        dispatch({
          type: actions.FILE_READ_SUCCESS,
          payload: rawData,
        });
      } catch (error) {
        console.error(error);
        dispatch({
          type: actions.FILE_READ_ERROR,
          payload: error,
        });
      }
    },
  };

  return actions;
};
