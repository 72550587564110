import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'id',
    label: i18n('entities.draftRecipeItem.fields.id'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.id'),
      {},
    ),
  },
  {
    name: 'item',
    label: i18n('entities.draftRecipeItem.fields.item'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.item'),
      {},
    ),
  },
  {
    name: 'prepNotes',
    label: 'Prep Notes',
    schema: schemas.string('Prep Notes', {}),
  },
  {
    name: 'recipeQty',
    label: i18n('entities.draftRecipeItem.fields.recipeQty'),
    schema: schemas.integer(
      i18n('entities.draftRecipeItem.fields.recipeQty'),
      {},
    ),
  },
  {
    name: 'recipeUnit',
    label: i18n('entities.draftRecipeItem.fields.recipeUnit'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.recipeUnit'),
      {},
    ),
  },
  {
    name: 'recipeName',
    label: i18n('entities.draftRecipeItem.fields.recipeName'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.recipeName'),
      {},
    ),
  },

  {
    name: 'methods',
    label: i18n('entities.draftRecipeItem.fields.methods'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.methods'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.draftRecipeItem.fields.images'),
    schema: schemas.images(
      i18n('entities.draftRecipeItem.fields.images'),
      {},
    ),
  },
  {
    name: 'storage',
    label: i18n('entities.draftRecipeItem.fields.storage'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.storage'),
      {},
    ),
  },
  {
    name: 'expiryDate',
    label: i18n('entities.draftRecipeItem.fields.expiryDate'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.expiryDate'),
      {},
    ),
  },
  {
    name: 'allergens',
    label: i18n('entities.draftRecipeItem.fields.allergens'),
    schema: schemas.stringArray(
      i18n('entities.draftRecipeItem.fields.allergens'),
      {},
    ),
  },
  {
    name: 'arName',
    label: i18n('entities.draftRecipeItem.fields.arName'),
    schema: schemas.string(
      i18n('entities.draftRecipeItem.fields.arName'),
      {},
    ),
  },
  {
    name: 'storageType',
    label: i18n('entities.draftRecipeItem.fields.storageType'),
    schema: schemas.relationToOne(
      i18n('entities.draftRecipeItem.fields.storageType'),
      {},
    ),
  },
  {
    name: 'category',
    label: i18n('entities.draftRecipeItem.fields.category'),
    schema: schemas.relationToOne(
      i18n('entities.draftRecipeItem.fields.category'),
      {},
    ),
  },
  {
    name: 'bpCode',
    label: i18n('entities.draftRecipeItem.fields.bpCode'),
    schema: schemas.relationToOne(
      i18n('entities.draftRecipeItem.fields.bpCode'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.draftRecipeItem.fields.unit'),
    schema: schemas.relationToOne(
      i18n('entities.draftRecipeItem.fields.unit'),
      {},
    ),
  },
];
