import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/ingredient/importer/ingredientImporterSelectors';
import IngredientService from 'src/modules/ingredient/ingredientService';
import fields from 'src/modules/ingredient/importer/ingredientImporterFields';
import { i18n } from 'src/i18n';

const ingredientImporterActions = importerActions(
  'INGREDIENT_IMPORTER',
  selectors,
  IngredientService.import,
  fields,
  i18n('entities.ingredient.importer.fileName'),
);

export default ingredientImporterActions;
