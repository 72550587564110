import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/draftRecipeItem/importer/recipeItemImporterSelectors';
import RecipeItemService from 'src/modules/draftRecipeItem/recipeItemService';
import fields from 'src/modules/draftRecipeItem/importer/recipeItemImporterFields';
import { i18n } from 'src/i18n';

const recipeItemImporterActions = importerActions(
  'RECIPEITEM_IMPORTER',
  selectors,
  RecipeItemService.import,
  fields,
  i18n('entities.recipeItem.importer.fileName'),
);

export default recipeItemImporterActions;
