import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.skuIngredientsOp.fields.id'),
  },
  {
    name: 'item',
    label: i18n('entities.skuIngredientsOp.fields.item'),
  },
  {
    name: 'itemAr',
    label: i18n('entities.skuIngredientsOp.fields.itemAr'),
  },
  {
    name: 'category',
    label: i18n('entities.skuIngredientsOp.fields.category'),
  },
  {
    name: 'sku',
    label: i18n('entities.skuIngredientsOp.fields.sku'),
  },
  {
    name: 'unit',
    label: i18n('entities.skuIngredientsOp.fields.unit'),
  },
  {
    name: 'root',
    label: i18n('entities.skuIngredientsOp.fields.root'),
  },
  {
    name: 'storage',
    label: i18n('entities.skuIngredientsOp.fields.storage'),
  },
  {
    name: 'desc',
    label: i18n('entities.skuIngredientsOp.fields.desc'),
  },
  {
    name: 'brand',
    label: i18n('entities.skuIngredientsOp.fields.brand'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.skuIngredientsOp.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.skuIngredientsOp.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
