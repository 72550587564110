import list from 'src/modules/productionPlan/list/productionPlanListReducers';
import form from 'src/modules/productionPlan/form/productionPlanFormReducers';
import view from 'src/modules/productionPlan/view/productionPlanViewReducers';
import destroy from 'src/modules/productionPlan/destroy/productionPlanDestroyReducers';
import importerReducer from 'src/modules/productionPlan/importer/productionPlanImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
