export const checkIngredientImporter = (
  row,
  ingredientFields,
) => {
  return Object?.keys(row)?.every((key) =>
    ingredientFields?.includes(key),
  );
};

export const callbackFn = (input) => {};

export const findMenuCategoryId = (
  menuCategory,
  categories,
) => {
  let item = categories?.find(
    (category) => category?.name === menuCategory?.trim(),
  );
  return item?.id;
};

export const findIngredientId = (
  ingredients,
  itemName,
  preferredBrand,
) => {
  let item = ingredients?.find(
    (item) =>
      item?.name === itemName?.trim() &&
      item?.marketBrand?.name === preferredBrand?.trim(),
  );
  return item?.id;
};

export const findBatchId = (batches, itemName) => {
  let item = batches?.find(
    (item) => item?.recipeName === itemName?.trim(),
  );
  return item?.id;
};

export const formatBatchItems = (
  data,
  ingredients,
  batches,
  prepNotes,
) => {
  let notes = prepNotes?.split(',');
  let items = data?.split(',');
  for (let i = 0; i < items?.length; i++) {
    let item = items[i]?.split('/');
    let itemName = item[0];
    let itemUnit = item[2];
    let itemQty = parseFloat(item[1]);
    let name =
      findIngredientId(ingredients, itemName, item[3]) ||
      null;
    let batchRecipe =
      findBatchId(batches, itemName) || null;
    let prepNote = notes[i];
    let preferredBrand = item[3];
    let alternativeBrand = '';
    let itemYield = item[4];

    let itemObj = {
      itemUnit,
      itemQty,
      name,
      batchRecipe,
      prepNote,
      preferredBrand,
      alternativeBrand,
      itemYield,
    };
    items[i] = itemObj;
  }
  return items;
};

export const formatLineBuildItems = (
  data,
  ingredients,
  batches,
  prepNotes,
) => {
  let items = data?.split(',');
  for (let i = 0; i < items?.length; i++) {
    let item = items[i]?.split('/');
    let itemName = item[0];
    let itemUnit = item[2];
    let itemQty = parseFloat(item[1]);
    let ingredient =
      findIngredientId(ingredients, itemName, item[3]) ||
      null;
    let batchRecipe =
      findBatchId(batches, itemName) || null;
    let preferredBrand = item[3];
    let alternativeBrand = '';
    let itemYield = item[4];

    let itemObj = {
      itemUnit,
      itemQty,
      ingredient,
      batchRecipe,
      preferredBrand,
      alternativeBrand,
      itemYield,
    };

    items[i] = itemObj;
  }

  return items;
};

export const ingredientsImporterFields = [
  'bpCode',
  'marketBrand',
  'name',
  'storageType',
  'category',
  'purchaseUnit',
  'conversionFactor',
  'usageUnit',
  'isSellable',
  'purchaseType',
  'ingredientYield',
  'purchasePackage',
  'purchasePackageUnit',
  'ingredientUnit',
  'purchasePackageQty',
  'arabicName',
  'id',
  'sku',
];

export const formatImportableRowForIngredientsCosts = (
  ingredient,
  importableRow,
  unitsIds,
  marketBrandsIds,
) => {
  importableRow = {
    ...ingredient,
    category: ingredient?.category?.id,
    bpCode: ingredient?.bpCodeId,
    storageType: ingredient?.storageType?.id,
    cost: importableRow['cost'],
    costPurchaseUnit: importableRow['costPurchaseUnit'],
    purchaseUnit:
      unitsIds[importableRow['purchaseUnit']] ||
      ingredient?.purchaseUnit?.id,
    marketBrand:
      marketBrandsIds[importableRow['marketBrand']] ||
      ingredient?.marketBrand?.id,
    fromImporter: false,
  };

  return importableRow;
};

export const formatImportableRowForBPIngredients = (
  ingredient,
  importableRow,
) => {
  importableRow = {
    ...ingredient,
    category: ingredient?.category?.id,
    purchaseUnit: ingredient?.purchaseUnit?.id,
    bpCode: ingredient?.bpCodeId,
    marketBrand: ingredient?.marketBrand?.id,
    storageType: ingredient?.storageType?.id,
    cost: importableRow['storageType'],
    costPurchaseUnit: importableRow['sku'],
    fromImporter: false,
  };
  return importableRow;
};

export const formatImportableRowForBatches = (
  importableRow,
  storageTypesIds,
  categoriesIds,
  unitsIds,
  bpCodesIds,
  ingredients,
  batches,
) => {
  importableRow = {
    ...importableRow,
    id: importableRow['id'] || null,
    storageType:
      storageTypesIds[importableRow['storageType']],
    category: categoriesIds[importableRow['category']],
    unit: unitsIds[importableRow['unit']],
    bpCode: bpCodesIds[importableRow['bpCode']],
    arName: importableRow['arName'] || '',
    items: formatBatchItems(
      importableRow['item'],
      ingredients,
      batches,
      importableRow['prepNotes'],
    ),
    fromImporter: true,
  };
  return importableRow;
};

export const formatImportableRowForIngredients = (
  importableRow,
  bpCodesIds,
  storageTypesIds,
  unitsIds,
  categoriesIds,
  marketBrandsIds,
) => {
  importableRow = {
    id: importableRow['id'] || null,
    usageUnit: importableRow?.usageUnit,
    conversionFactor: importableRow?.conversionFactor,
    bpCode: bpCodesIds[importableRow['bpCode']] || null,
    name: importableRow['name'],
    arabicName: importableRow['arabicName'],
    storageType:
      storageTypesIds[importableRow['storageType']],
    category: categoriesIds[importableRow['category']],
    purchaseUnit: unitsIds[importableRow['purchaseUnit']],
    marketBrand:
      marketBrandsIds[importableRow['marketBrand']] || null,
    isSellable: 
      importableRow['isSellable'] === 'No' ||
      importableRow['isSellable'] === 'false'
        ? false
        : true,
    purchaseType: importableRow['purchaseType'] || '',
    ingredientYield: importableRow['ingredientYield'] || '',
    purchasePackage: importableRow['purchasePackage'] || '',
    purchasePackageUnit:
      importableRow['purchasePackageUnit'] || '',
    ingredientUnit: importableRow['ingredientUnit'] || '',
    purchasePackageQty:
      importableRow['purchasePackageQty'] || 0,
    sku: importableRow['sku'] || '',
    fromImporter: true,
  };
  return importableRow;
};

export const formatImportableRowForLB = (
  importableRow,
  menuCategories,
  ingredients,
  batches,
) => {
  importableRow = {
    ...importableRow,
    id: importableRow['id'] || null,
    menuCategoryName: findMenuCategoryId(
      importableRow['menuCategoryName'],
      menuCategories,
    ),
    isAddOn:
      importableRow['isAddOn'] === 'No' ||
      importableRow['isAddOn'] === 'False'
        ? false
        : true,
    isExtra:
      importableRow['isExtra'] === 'No' ||
      importableRow['isExtra'] === 'False'
        ? false
        : true,
    allergens: importableRow['allergens'] || [],
    addons: importableRow['addons'] || [],
    items: formatLineBuildItems(
      importableRow['items'],
      ingredients,
      batches,
      importableRow['prepNotes'],
    ),
    fromImporter: true,
  };

  return importableRow;
};
