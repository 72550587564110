import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'ingredient',
    label: i18n(
      'entities.ingredientsSuppliers.fields.ingredient',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.ingredientsSuppliers.fields.ingredient',
      ),
      {},
    ),
  },
  {
    name: 'supplier',
    label: i18n(
      'entities.ingredientsSuppliers.fields.supplier',
    ),
    schema: schemas.relationToOne(
      i18n('entities.ingredientsSuppliers.fields.supplier'),
      {},
    ),
  },
  {
    name: 'cost',
    label: i18n(
      'entities.ingredientsSuppliers.fields.cost',
    ),
    schema: schemas.decimal(
      i18n('entities.ingredientsSuppliers.fields.cost'),
      {},
    ),
  },
];
