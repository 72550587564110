import list from 'src/modules/brandMenu/list/brandMenuListReducers';
import form from 'src/modules/brandMenu/form/brandMenuFormReducers';
import view from 'src/modules/brandMenu/view/brandMenuViewReducers';
import destroy from 'src/modules/brandMenu/destroy/brandMenuDestroyReducers';
import importerReducer from 'src/modules/brandMenu/importer/brandMenuImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
