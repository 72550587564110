import list from 'src/modules/productionOrder/list/productionOrderListReducers';
import form from 'src/modules/productionOrder/form/productionOrderFormReducers';
import view from 'src/modules/productionOrder/view/productionOrderViewReducers';
import destroy from 'src/modules/productionOrder/destroy/productionOrderDestroyReducers';
import importerReducer from 'src/modules/productionOrder/importer/productionOrderImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
