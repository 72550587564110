import list from 'src/modules/ingredientRoot/list/storageTypeListReducers';
import form from 'src/modules/ingredientRoot/form/storageTypeFormReducers';
import view from 'src/modules/ingredientRoot/view/storageTypeViewReducers';
import destroy from 'src/modules/ingredientRoot/destroy/storageTypeDestroyReducers';
import importerReducer from 'src/modules/ingredientRoot/importer/storageTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
