import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/item/importer/itemImporterSelectors';
import ItemService from 'src/modules/item/itemService';
import fields from 'src/modules/item/importer/itemImporterFields';
import { i18n } from 'src/i18n';

const itemImporterActions = importerActions(
  'ITEM_IMPORTER',
  selectors,
  ItemService.import,
  fields,
  i18n('entities.item.importer.fileName'),
);

export default itemImporterActions;
