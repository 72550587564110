/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {
      ingredientPackagingImages: {
        id: 'ingredientPackagingImages',
        folder:
          'tenant/:tenantId/ingredient/packagingImages',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      supplyHistoryAttachment: {
        id: 'supplyHistoryAttachment',
        folder: 'tenant/:tenantId/supplyHistory/attachment',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
      recipeItemImages: {
        id: 'recipeItemImages',
        folder: 'tenant/:tenantId/recipeItem/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      lineBuildImages: {
        id: 'lineBuildImages',
        folder: 'tenant/:tenantId/lineBuild/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      draftRecipeItemImages: {
        id: 'draftRecipeItemImages',
        folder: 'tenant/:tenantId/draftRecipeItem/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      draftLineBuildImages: {
        id: 'draftLineBuildImages',
        folder: 'tenant/:tenantId/draftLineBuild/images',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      lineBuildPackagingImages: {
        id: 'lineBuildPackagingImages',
        folder:
          'tenant/:tenantId/lineBuild/packagingImages',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      cuttingCuttingImage: {
        id: 'cuttingCuttingImage',
        folder: 'tenant/:tenantId/cutting/cuttingImage',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      brandMenuImage: {
        id: 'brandMenuImage',
        folder: 'tenant/:tenantId/brandMenu/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      brandMenuItemImage: {
        id: 'brandMenuItemImage',
        folder: 'tenant/:tenantId/brandMenuItem/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      comboItemImage: {
        id: 'comboItemImage',
        folder: 'tenant/:tenantId/comboItem/image',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

    };
  }
}