import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/labStocks/importer/labStocksImporterSelectors';
import LabStocksService from 'src/modules/labStocks/labStocksService';
import fields from 'src/modules/labStocks/importer/labStocksImporterFields';
import { i18n } from 'src/i18n';

const labStocksImporterActions = importerActions(
  'LABSTOCKS_IMPORTER',
  selectors,
  LabStocksService.import,
  fields,
  i18n('entities.labStocks.importer.fileName'),
);

export default labStocksImporterActions;
