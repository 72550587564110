import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/productionPlan/importer/productionPlanImporterSelectors';
import ProductionPlanService from 'src/modules/productionPlan/productionPlanService';
import fields from 'src/modules/productionPlan/importer/productionPlanImporterFields';
import { i18n } from 'src/i18n';

const productionPlanImporterActions = importerActions(
  'PRODUCTIONPLAN_IMPORTER',
  selectors,
  ProductionPlanService.import,
  fields,
  i18n('entities.productionPlan.importer.fileName'),
);

export default productionPlanImporterActions;