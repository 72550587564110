import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/conversionUnit/importer/conversionUnitImporterSelectors';
import ConversionUnitService from 'src/modules/conversionUnit/conversionUnitService';
import fields from 'src/modules/conversionUnit/importer/conversionUnitImporterFields';
import { i18n } from 'src/i18n';

const conversionUnitImporterActions = importerActions(
  'CONVERSIONUNIT_IMPORTER',
  selectors,
  ConversionUnitService.import,
  fields,
  i18n('entities.conversionUnit.importer.fileName'),
);

export default conversionUnitImporterActions;
