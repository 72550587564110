import list from 'src/modules/unit/list/unitListReducers';
import form from 'src/modules/unit/form/unitFormReducers';
import view from 'src/modules/unit/view/unitViewReducers';
import destroy from 'src/modules/unit/destroy/unitDestroyReducers';
import importerReducer from 'src/modules/unit/importer/unitImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
