import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import authSelectors from 'src/modules/auth/authSelectors';
import { useDispatch, useSelector } from 'react-redux';
import PermissionChecker from 'src/modules/auth/permissionChecker';
import actions from 'src/modules/layout/layoutActions';
import layoutSelectors from 'src/modules/layout/layoutSelectors';
import menus, { BPMenu, OpsUser, } from 'src/view/menus';
import clsx from 'clsx';
import _ from 'lodash';

import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { isCurrentUserAdmin, isCurrentUserOpsUser, } from '../helpers';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  darkDrawerPaper: {
    width: drawerWidth,
    color: 'white', 
    backgroundColor: '#15202B',
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
  },
  toolbar: theme.mixins.toolbar,
  listItemIcon: {
    minWidth: '48px',
  },
  listItemDisabled: {
    opacity: 0.5,
  },
}));

function Menu(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentTenant = useSelector(
    authSelectors.selectCurrentTenant,
  );
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  const isCurrentTenantTFL = currentTenant?.name?.toLowerCase()?.trim() === 'the food lab';

  let isAdmin = isCurrentUserAdmin(currentUser);
  let isOpsUser = isCurrentUserOpsUser(currentUser); 

  const menuVisible = useSelector(
    layoutSelectors.selectMenuVisible,
  );
  
  const permissionChecker = new PermissionChecker(
    currentTenant,
    currentUser,
  );


  useLayoutEffect(() => {
    const toggleMenuOnResize = () => {
      (window as any).innerWidth < 576
        ? dispatch(actions.doHideMenu())
        : dispatch(actions.doShowMenu());
    };

    toggleMenuOnResize();

    (window as any).addEventListener(
      'resize',
      toggleMenuOnResize,
    );

    return () => {
      (window as any).removeEventListener(
        'resize',
        toggleMenuOnResize,
      );
    };
  }, [dispatch]);

  const selectedKeys = () => {
    const url = props.url;

    const match = menus.find((option) => {
      if (option?.exact) {
        return url === option.path;
      }

      return (
        url === option?.path ||
        url.startsWith(option.path + '/')
      );
    });

    if (match) {
      return [match?.path];
    }

    return [];
  };

  const match = (permission) => {
    return permissionChecker.match(permission);
  };

  const lockedForCurrentPlan = (permission) => {
    return permissionChecker.lockedForCurrentPlan(
      permission,
    );
  };

  const [clicked, setClicked] = useState(false);
  const [clickedUrl, setClickedUrl] = useState('');

  const CustomRouterLink = (props) => (
    <div
      style={{
        flexGrow: 1,
      }}
    >
      <Link
        style={{
          textDecoration: 'none',
          color: 'inherit',
        }}
        {...props}
      />
    </div>
  );

  if (!menuVisible) {
    return null;
  };

  function renderMenuItems(menus) {
    return menus
      .map((menu) => {
        if(menu?.label === 'Production' && !isCurrentTenantTFL) return (null); 
        const hasChildren = menu?.children && menu?.children?.length > 0;

        const menuItem = !hasChildren ? (
          <CustomRouterLink key={menu.path} to={menu.path}>
            <ListItem
              button
              onClick={() => {
                if (hasChildren) {
                  setClicked(!clicked);
                }
              }}
            >
              <ListItemIcon
                className={clsx({
                  [classes.listItemIcon]: true,
                  [classes.active]: selectedKeys().includes(
                    menu.path,
                  ),
                })}
              >
                {menu.icon}
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.active]: selectedKeys().includes(
                    menu.path,
                  ),
                })}
              >
                {menu.label}
              </ListItemText>
              {hasChildren &&
              selectedKeys().includes(menu.path) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
          </CustomRouterLink>
        ) : (
          <div>
            <ListItem
              button
              onClick={() => {
                if (hasChildren) {
                  setClicked(!clicked);
                  setClickedUrl(menu.path);
                }
              }}
            >
              <ListItemIcon
                className={clsx({
                  [classes.listItemIcon]: true,
                  [classes.active]: selectedKeys().includes(
                    menu.path,
                  ),
                })}
              >
                {menu.icon}
              </ListItemIcon>
              <ListItemText
                className={clsx({
                  [classes.active]: selectedKeys().includes(
                    menu.path,
                  ),
                })}
              >
                {menu.label}
              </ListItemText>
              {hasChildren &&
              selectedKeys().includes(menu.path) ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </ListItem>
          </div>
        );

        if (hasChildren && clicked) {
          return (
            <React.Fragment key={menu.path}>
              {menuItem}
              <Collapse in={clickedUrl === menu.path}>
                <List component="div" disablePadding>
                  {renderMenuItems(menu.children)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        } else {
          return menuItem;
        }
      });
  };

  const { globalTheme, } = props; 

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      anchor="left"
      open={true}
      classes={{
        paper: globalTheme === 'dark' 
          ? classes.darkDrawerPaper 
          : classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar}></div>
      <List>
        {isAdmin && renderMenuItems(menus)}
        {!isAdmin && !isOpsUser && renderMenuItems(BPMenu)}
        {!isAdmin && !!isOpsUser && renderMenuItems(OpsUser)}
        {menus
          .filter((menu) =>
            lockedForCurrentPlan(menu.permissionRequired),
          )
          .map((menu) => (
            <ListItem
              key={menu.path}
              className={classes.listItemDisabled}
            >
              <ListItemIcon
                className={classes.listItemIcon}
              >
                {menu.icon}
              </ListItemIcon>
              <ListItemText>{menu.label}</ListItemText>
            </ListItem>
          ))}
      </List>
    </Drawer>
  );
}

export default Menu;
