import list from 'src/modules/storageType/list/storageTypeListReducers';
import form from 'src/modules/storageType/form/storageTypeFormReducers';
import view from 'src/modules/storageType/view/storageTypeViewReducers';
import destroy from 'src/modules/storageType/destroy/storageTypeDestroyReducers';
import importerReducer from 'src/modules/storageType/importer/storageTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
