import list from 'src/modules/draftRecipeItem/list/recipeItemListReducers';
import form from 'src/modules/draftRecipeItem/form/recipeItemFormReducers';
import view from 'src/modules/draftRecipeItem/view/recipeItemViewReducers';
import destroy from 'src/modules/draftRecipeItem/destroy/recipeItemDestroyReducers';
import importerReducer from 'src/modules/draftRecipeItem/importer/recipeItemImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
