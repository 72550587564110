import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/draftLineBuild/importer/lineBuildImporterSelectors';
import LineBuildService from 'src/modules/draftLineBuild/lineBuildService';
import fields from 'src/modules/draftLineBuild/importer/lineBuildImporterFields';
import { i18n } from 'src/i18n';

const lineBuildImporterActions = importerActions(
  'LINEBUILD_IMPORTER',
  selectors,
  LineBuildService.import,
  fields,
  i18n('entities.lineBuild.importer.fileName'),
);

export default lineBuildImporterActions;
