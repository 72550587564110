import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.supplyHistory.fields.id'),
  },
  {
    name: 'supplier',
    label: i18n('entities.supplyHistory.fields.supplier'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'ingredient',
    label: i18n('entities.supplyHistory.fields.ingredient'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'purchaseDate',
    label: i18n(
      'entities.supplyHistory.fields.purchaseDate',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'cost',
    label: i18n('entities.supplyHistory.fields.cost'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'section',
    label: i18n('entities.supplyHistory.fields.section'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'unit',
    label: i18n('entities.supplyHistory.fields.unit'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'unitsPerPack',
    label: i18n(
      'entities.supplyHistory.fields.unitsPerPack',
    ),
  },
  {
    name: 'vat',
    label: i18n('entities.supplyHistory.fields.vat'),
  },
  {
    name: 'amountVAT',
    label: i18n('entities.supplyHistory.fields.amountVAT'),
  },
  {
    name: 'pricePerUnit',
    label: i18n(
      'entities.supplyHistory.fields.pricePerUnit',
    ),
  },
  {
    name: 'lab',
    label: i18n('entities.supplyHistory.fields.lab'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'paymentMethod',
    label: i18n(
      'entities.supplyHistory.fields.paymentMethod',
    ),
  },
  {
    name: 'invoiceDate',
    label: i18n(
      'entities.supplyHistory.fields.invoiceDate',
    ),
  },
  {
    name: 'invoiceNo',
    label: i18n('entities.supplyHistory.fields.invoiceNo'),
  },
  {
    name: 'receivingNo',
    label: i18n(
      'entities.supplyHistory.fields.receivingNo',
    ),
  },
  {
    name: 'attachment',
    label: i18n('entities.supplyHistory.fields.attachment'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'qtyPurchased',
    label: i18n(
      'entities.supplyHistory.fields.qtyPurchased',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.supplyHistory.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.supplyHistory.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
