import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/skuIngredientsOp/importer/skuIngredientsOpImporterSelectors';
import SkuIngredientsOpService from 'src/modules/skuIngredientsOp/skuIngredientsOpService';
import fields from 'src/modules/skuIngredientsOp/importer/skuIngredientsOpImporterFields';
import { i18n } from 'src/i18n';

const skuIngredientsOpImporterActions = importerActions(
  'SKUINGREDIENTSOP_IMPORTER',
  selectors,
  SkuIngredientsOpService.import,
  fields,
  i18n('entities.skuIngredientsOp.importer.fileName'),
);

export default skuIngredientsOpImporterActions;