import list from 'src/modules/draftLineBuildItem/list/lineBuildItemListReducers';
import form from 'src/modules/draftLineBuildItem/form/lineBuildItemFormReducers';
import view from 'src/modules/draftLineBuildItem/view/lineBuildItemViewReducers';
import destroy from 'src/modules/draftLineBuildItem/destroy/lineBuildItemDestroyReducers';
import importerReducer from 'src/modules/draftLineBuildItem/importer/lineBuildItemImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
