import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/lineBuildMenuItemMapping/importer/lineBuildMenuItemMappingImporterSelectors';
import LineBuildMenuItemMappingService from 'src/modules/lineBuildMenuItemMapping/lineBuildMenuItemMappingService';
import fields from 'src/modules/lineBuildMenuItemMapping/importer/lineBuildMenuItemMappingImporterFields';
import { i18n } from 'src/i18n';

const lineBuildMenuItemMappingImporterActions =
  importerActions(
    'LINEBUILDMENUITEMMAPPING_IMPORTER',
    selectors,
    LineBuildMenuItemMappingService.import,
    fields,
    i18n(
      'entities.lineBuildMenuItemMapping.importer.fileName',
    ),
  );

export default lineBuildMenuItemMappingImporterActions;
