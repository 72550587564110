import StorageTypeService from 'src/modules/storageType/storageTypeService';
import CategoryService from 'src/modules/category/categoryService';
import BpCodeService from 'src/modules/bpCode/bpCodeService';
import UnitService from 'src/modules/unit/unitService';
import MarketBrandService from 'src/modules/marketBrand/marketBrandService';

export const getBpCodes = async (setBpCodesIdsFn) => {
  let { rows } = await BpCodeService?.list(
    {},
    null,
    1000000,
    0,
  );
  let newIdsMap = rows?.reduce((acc, row) => {
    acc[row?.name] = row?.id;
    return acc;
  }, {});

  setBpCodesIdsFn(newIdsMap);
  return newIdsMap;
};

export const getUnits = async (setUnitsIdsFn) => {
  let { rows } = await UnitService?.list(
    {},
    null,
    1000000,
    0,
  );
  let newIdsMap = rows?.reduce((acc, row) => {
    acc[row?.name] = row?.id;
    return acc;
  }, {});

  setUnitsIdsFn(newIdsMap);
  return newIdsMap;
};

export const getMarketBrands = async (
  setMarketBrandsIdsFn,
) => {
  let { rows } = await MarketBrandService?.list(
    {},
    null,
    1000000,
    0,
  );
  let newIdsMap = rows?.reduce((acc, row) => {
    acc[row?.name] = row?.id;
    return acc;
  }, {});

  setMarketBrandsIdsFn(newIdsMap);
  return newIdsMap;
};

export const getStorageTypes = async (
  setStorageTypesIdsFn,
) => {
  let { rows } = await StorageTypeService?.list(
    {},
    null,
    1000000,
    0,
  );
  let newIdsMap = rows?.reduce((acc, row) => {
    acc[row?.name] = row?.id;
    return acc;
  }, {});

  setStorageTypesIdsFn(newIdsMap);
  return newIdsMap;
};

export const getCategories = async (setCategoriesIdsFn) => {
  let { rows } = await CategoryService?.list(
    {},
    null,
    1000000,
    0,
  );
  let newIdsMap = rows?.reduce((acc, row) => {
    acc[row?.name] = row?.id;
    return acc;
  }, {});

  setCategoriesIdsFn(newIdsMap);
  return newIdsMap;
};

export const mapFields = (fields) => {
  return fields?.map((field) => field?.name);
};
