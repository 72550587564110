import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.lineBuildExtra.fields.id'),
  },
  {
    name: 'ingredient',
    label: i18n(
      'entities.lineBuildExtra.fields.ingredient',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'lineBuild',
    label: i18n('entities.lineBuildExtra.fields.lineBuild'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'quantity',
    label: i18n('entities.lineBuildExtra.fields.quantity'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'unit',
    label: i18n('entities.lineBuildExtra.fields.unit'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.lineBuildExtra.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.lineBuildExtra.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
