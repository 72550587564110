import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/ingredientsUnits/importer/ingredientsUnitsImporterSelectors';
import IngredientsUnitsService from 'src/modules/ingredientsUnits/ingredientsUnitsService';
import fields from 'src/modules/ingredientsUnits/importer/ingredientsUnitsImporterFields';
import { i18n } from 'src/i18n';

const ingredientsUnitsImporterActions = importerActions(
  'INGREDIENTSUNITS_IMPORTER',
  selectors,
  IngredientsUnitsService.import,
  fields,
  i18n('entities.ingredientsUnits.importer.fileName'),
);

export default ingredientsUnitsImporterActions;
