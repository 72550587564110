import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.menuItem.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.menuItem.fields.name'),
  },
  {
    name: 'description',
    label: i18n('entities.menuItem.fields.description'),
  },
  {
    name: 'ingredients',
    label: i18n('entities.menuItem.fields.ingredients'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'batches',
    label: i18n('entities.menuItem.fields.batches'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recipe',
    label: i18n('entities.menuItem.fields.recipe'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.menuItem.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.menuItem.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
