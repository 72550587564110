import list from 'src/modules/cutting/list/cuttingListReducers';
import form from 'src/modules/cutting/form/cuttingFormReducers';
import view from 'src/modules/cutting/view/cuttingViewReducers';
import destroy from 'src/modules/cutting/destroy/cuttingDestroyReducers';
import importerReducer from 'src/modules/cutting/importer/cuttingImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
