import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.lineBuildItem.fields.id'),
  },
  {
    name: 'itemQty',
    label: i18n('entities.lineBuildItem.fields.itemQty'),
  },
  {
    name: 'itemUnit',
    label: i18n('entities.lineBuildItem.fields.itemUnit'),
  },
  {
    name: 'ingredient',
    label: i18n('entities.lineBuildItem.fields.ingredient'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'batchRecipe',
    label: i18n(
      'entities.lineBuildItem.fields.batchRecipe',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'preferredBrand',
    label: i18n(
      'entities.lineBuildItem.fields.preferredBrand',
    ),
  },
  {
    name: 'alternativeBrand',
    label: i18n(
      'entities.lineBuildItem.fields.alternativeBrand',
    ),
  },
  {
    name: 'prepNote',
    label: i18n('entities.lineBuildItem.fields.prepNote'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.lineBuildItem.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.lineBuildItem.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
