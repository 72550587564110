import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/ingredientRoot/importer/storageTypeImporterSelectors';
import StorageTypeService from 'src/modules/ingredientRoot/storageTypeService';
import fields from 'src/modules/ingredientRoot/importer/storageTypeImporterFields';
import { i18n } from 'src/i18n';

const storageTypeImporterActions = importerActions(
  'INGREDIENTROOT_IMPORTER',
  selectors,
  StorageTypeService.import,
  fields,
  i18n('entities.ingredientRoot.importer.fileName'),
);

export default storageTypeImporterActions;
