import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.productionPlan.fields.id'),
  },
  {
    name: 'sku',
    label: i18n('entities.productionPlan.fields.sku'),
  },
  {
    name: 'qtyRequired',
    label: i18n('entities.productionPlan.fields.qtyRequired'),
  },
  {
    name: 'location',
    label: i18n('entities.productionPlan.fields.location'),
  },
  {
    name: 'expectedDate',
    label: i18n('entities.productionPlan.fields.expectedDate'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.productionPlan.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.productionPlan.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
