import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/marketBrand/importer/marketBrandImporterSelectors';
import MarketBrandService from 'src/modules/marketBrand/marketBrandService';
import fields from 'src/modules/marketBrand/importer/marketBrandImporterFields';
import { i18n } from 'src/i18n';

const marketBrandImporterActions = importerActions(
  'MARKETBRAND_IMPORTER',
  selectors,
  MarketBrandService.import,
  fields,
  i18n('entities.marketBrand.importer.fileName'),
);

export default marketBrandImporterActions;
