import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/bpIngredient/importer/bpIngredientImporterSelectors';
import BpIngredientService from 'src/modules/bpIngredient/bpIngredientService';
import fields from 'src/modules/bpIngredient/importer/bpIngredientImporterFields';
import { i18n } from 'src/i18n';

const bpIngredientImporterActions = importerActions(
  'BPINGREDIENT_IMPORTER',
  selectors,
  BpIngredientService.import,
  fields,
  i18n('entities.bpIngredient.importer.fileName'),
);

export default bpIngredientImporterActions;
