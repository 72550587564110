export function MaterialSymbolsWarehouse(props) {
    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="2em" 
            height="2em" 
            viewBox="0 0 24 24" {...props}
        >
            <path fill="currentColor" d="M2 21V7l10-4l10 4v14h-6v-8H8v8zm7 0v-2h2v2zm2-3v-2h2v2zm2 3v-2h2v2z"/>
        </svg>
    );
}; 

