import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/bpLabs/importer/bpLabsImporterSelectors';
import BpLabsService from 'src/modules/bpLabs/bpLabsService';
import fields from 'src/modules/bpLabs/importer/bpLabsImporterFields';
import { i18n } from 'src/i18n';

const bpLabsImporterActions = importerActions(
  'BPLABS_IMPORTER',
  selectors,
  BpLabsService.import,
  fields,
  i18n('entities.bpLabs.importer.fileName'),
);

export default bpLabsImporterActions;
