import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.conversionUnit.fields.id'),
  },
  {
    name: 'unitName',
    label: i18n('entities.conversionUnit.fields.unitName'),
  },
  {
    name: 'baseUnit',
    label: i18n('entities.conversionUnit.fields.baseUnit'),
  },
  {
    name: 'conversionFactor',
    label: i18n(
      'entities.conversionUnit.fields.conversionFactor',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.conversionUnit.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.conversionUnit.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
