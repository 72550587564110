import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import brandMenuCategoryEnumerators from 'src/modules/brandMenuCategory/brandMenuCategoryEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.brandMenuCategory.fields.name'),
    schema: schemas.string(
      i18n('entities.brandMenuCategory.fields.name'),
      {},
    ),
  },
  {
    name: 'type',
    label: i18n('entities.brandMenuCategory.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.brandMenuCategory.fields.type'),
      {
        "options": brandMenuCategoryEnumerators.type
      },
    ),
  },
];