import list from 'src/modules/item/list/itemListReducers';
import form from 'src/modules/item/form/itemFormReducers';
import view from 'src/modules/item/view/itemViewReducers';
import destroy from 'src/modules/item/destroy/itemDestroyReducers';
import importerReducer from 'src/modules/item/importer/itemImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
