import SkuIngredientsOpService from 'src/modules/skuIngredientsOp/skuIngredientsOpService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import {
  updateOpsIngredientsSheet,
} from "./helpers"; 

const prefix = 'SKUINGREDIENTSOP_FORM';

const skuIngredientsOpFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: skuIngredientsOpFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await SkuIngredientsOpService.find(id);
      }

      dispatch({
        type: skuIngredientsOpFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: skuIngredientsOpFormActions.INIT_ERROR,
      });

      getHistory().push('/sku-ingredients-op');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: skuIngredientsOpFormActions.CREATE_STARTED,
      });

      await SkuIngredientsOpService.create(values);

      dispatch({
        type: skuIngredientsOpFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.skuIngredientsOp.create.success'),
      );

      getHistory().push('/sku-ingredients-op');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: skuIngredientsOpFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: skuIngredientsOpFormActions.UPDATE_STARTED,
      });

      let rec = await SkuIngredientsOpService.update(id, values);
      console.log(rec); 
      // await updateOpsIngredientsSheet(id, values, rec)

      dispatch({
        type: skuIngredientsOpFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.skuIngredientsOp.update.success'),
      );

      getHistory().push('/sku-ingredients-op');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: skuIngredientsOpFormActions.UPDATE_ERROR,
      });
    }
  },
};

export default skuIngredientsOpFormActions;
