import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.labStocks.fields.id'),
  },
  {
    name: 'lab',
    label: i18n('entities.labStocks.fields.lab'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'ingredient',
    label: i18n('entities.labStocks.fields.ingredient'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'amount',
    label: i18n('entities.labStocks.fields.amount'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'unit',
    label: i18n('entities.labStocks.fields.unit'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.labStocks.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.labStocks.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
