import list from 'src/modules/batches/list/batchesListReducers';
import form from 'src/modules/batches/form/batchesFormReducers';
import view from 'src/modules/batches/view/batchesViewReducers';
import destroy from 'src/modules/batches/destroy/batchesDestroyReducers';
import importerReducer from 'src/modules/batches/importer/batchesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
