import list from 'src/modules/menuCategory/list/menuCategoryListReducers';
import form from 'src/modules/menuCategory/form/menuCategoryFormReducers';
import view from 'src/modules/menuCategory/view/menuCategoryViewReducers';
import destroy from 'src/modules/menuCategory/destroy/menuCategoryDestroyReducers';
import importerReducer from 'src/modules/menuCategory/importer/menuCategoryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
