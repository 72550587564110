import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import lineBuildExtraEnumerators from 'src/modules/lineBuildExtra/lineBuildExtraEnumerators';

export default [
  {
    name: 'ingredient',
    label: i18n(
      'entities.lineBuildExtra.fields.ingredient',
    ),
    schema: schemas.relationToOne(
      i18n('entities.lineBuildExtra.fields.ingredient'),
      {},
    ),
  },
  {
    name: 'lineBuild',
    label: i18n('entities.lineBuildExtra.fields.lineBuild'),
    schema: schemas.relationToOne(
      i18n('entities.lineBuildExtra.fields.lineBuild'),
      {},
    ),
  },
  {
    name: 'quantity',
    label: i18n('entities.lineBuildExtra.fields.quantity'),
    schema: schemas.decimal(
      i18n('entities.lineBuildExtra.fields.quantity'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.lineBuildExtra.fields.unit'),
    schema: schemas.enumerator(
      i18n('entities.lineBuildExtra.fields.unit'),
      {
        options: lineBuildExtraEnumerators.unit,
      },
    ),
  },
];
