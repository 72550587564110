import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'id',
    label: i18n('entities.recipeItem.fields.id'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.id'),
      {},
    ),
  },
  {
    name: 'item',
    label: i18n('entities.recipeItem.fields.item'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.item'),
      {},
    ),
  },
  {
    name: 'prepNotes',
    label: 'Prep Notes',
    schema: schemas.string('Prep Notes', {}),
  },
  {
    name: 'recipeQty',
    label: i18n('entities.recipeItem.fields.recipeQty'),
    schema: schemas.integer(
      i18n('entities.recipeItem.fields.recipeQty'),
      {},
    ),
  },
  {
    name: 'recipeUnit',
    label: i18n('entities.recipeItem.fields.recipeUnit'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.recipeUnit'),
      {},
    ),
  },
  {
    name: 'recipeName',
    label: i18n('entities.recipeItem.fields.recipeName'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.recipeName'),
      {},
    ),
  },

  {
    name: 'methods',
    label: i18n('entities.recipeItem.fields.methods'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.methods'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.recipeItem.fields.images'),
    schema: schemas.images(
      i18n('entities.recipeItem.fields.images'),
      {},
    ),
  },
  {
    name: 'storage',
    label: i18n('entities.recipeItem.fields.storage'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.storage'),
      {},
    ),
  },
  {
    name: 'expiryDate',
    label: i18n('entities.recipeItem.fields.expiryDate'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.expiryDate'),
      {},
    ),
  },
  {
    name: 'allergens',
    label: i18n('entities.recipeItem.fields.allergens'),
    schema: schemas.stringArray(
      i18n('entities.recipeItem.fields.allergens'),
      {},
    ),
  },
  {
    name: 'arName',
    label: i18n('entities.recipeItem.fields.arName'),
    schema: schemas.string(
      i18n('entities.recipeItem.fields.arName'),
      {},
    ),
  },
  // {
  //   name: 'weightPerPiece',
  //   label: i18n('entities.recipeItem.fields.weightPerPiece'),
  //   schema: schemas.string(
  //     i18n('entities.recipeItem.fields.weightPerPiece'),
  //     {},
  //   ),
  // },
  // {
  //   name: 'incomplete',
  //   label: i18n('entities.recipeItem.fields.incomplete'),
  //   schema: schemas.boolean(
  //     i18n('entities.recipeItem.fields.incomplete'),
  //     {},
  //   ),
  // },
  {
    name: 'storageType',
    label: i18n('entities.recipeItem.fields.storageType'),
    schema: schemas.relationToOne(
      i18n('entities.recipeItem.fields.storageType'),
      {},
    ),
  },
  {
    name: 'category',
    label: i18n('entities.recipeItem.fields.category'),
    schema: schemas.relationToOne(
      i18n('entities.recipeItem.fields.category'),
      {},
    ),
  },
  {
    name: 'bpCode',
    label: i18n('entities.recipeItem.fields.bpCode'),
    schema: schemas.relationToOne(
      i18n('entities.recipeItem.fields.bpCode'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.recipeItem.fields.unit'),
    schema: schemas.relationToOne(
      i18n('entities.recipeItem.fields.unit'),
      {},
    ),
  },
];
