import list from 'src/modules/comboItem/list/comboItemListReducers';
import form from 'src/modules/comboItem/form/comboItemFormReducers';
import view from 'src/modules/comboItem/view/comboItemViewReducers';
import destroy from 'src/modules/comboItem/destroy/comboItemDestroyReducers';
import importerReducer from 'src/modules/comboItem/importer/comboItemImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
