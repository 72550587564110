import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/batches/importer/batchesImporterSelectors';
import BatchesService from 'src/modules/batches/batchesService';
import fields from 'src/modules/batches/importer/batchesImporterFields';
import { i18n } from 'src/i18n';

const batchesImporterActions = importerActions(
  'BATCHES_IMPORTER',
  selectors,
  BatchesService.import,
  fields,
  i18n('entities.batches.importer.fileName'),
);

export default batchesImporterActions;
