import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/lineBuildExtra/importer/lineBuildExtraImporterSelectors';
import LineBuildExtraService from 'src/modules/lineBuildExtra/lineBuildExtraService';
import fields from 'src/modules/lineBuildExtra/importer/lineBuildExtraImporterFields';
import { i18n } from 'src/i18n';

const lineBuildExtraImporterActions = importerActions(
  'LINEBUILDEXTRA_IMPORTER',
  selectors,
  LineBuildExtraService.import,
  fields,
  i18n('entities.lineBuildExtra.importer.fileName'),
);

export default lineBuildExtraImporterActions;
