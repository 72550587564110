import list from 'src/modules/lineBuildAddon/list/lineBuildAddonListReducers';
import form from 'src/modules/lineBuildAddon/form/lineBuildAddonFormReducers';
import view from 'src/modules/lineBuildAddon/view/lineBuildAddonViewReducers';
import destroy from 'src/modules/lineBuildAddon/destroy/lineBuildAddonDestroyReducers';
import importerReducer from 'src/modules/lineBuildAddon/importer/lineBuildAddonImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
