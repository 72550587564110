import list from 'src/modules/skuIngredientsOp/list/skuIngredientsOpListReducers';
import form from 'src/modules/skuIngredientsOp/form/skuIngredientsOpFormReducers';
import view from 'src/modules/skuIngredientsOp/view/skuIngredientsOpViewReducers';
import destroy from 'src/modules/skuIngredientsOp/destroy/skuIngredientsOpDestroyReducers';
import importerReducer from 'src/modules/skuIngredientsOp/importer/skuIngredientsOpImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
