import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.ingredientsUnits.fields.name'),
    schema: schemas.string(
      i18n('entities.ingredientsUnits.fields.name'),
      {},
    ),
  },
  {
    name: 'symbol',
    label: i18n('entities.ingredientsUnits.fields.symbol'),
    schema: schemas.string(
      i18n('entities.ingredientsUnits.fields.symbol'),
      {},
    ),
  },
  {
    name: 'isbaseunit',
    label: i18n(
      'entities.ingredientsUnits.fields.isbaseunit',
    ),
    schema: schemas.boolean(
      i18n('entities.ingredientsUnits.fields.isbaseunit'),
      {},
    ),
  },
  {
    name: 'conversion',
    label: i18n(
      'entities.ingredientsUnits.fields.conversion',
    ),
    schema: schemas.decimal(
      i18n('entities.ingredientsUnits.fields.conversion'),
      {},
    ),
  },
  {
    name: 'parentUnitType',
    label: i18n(
      'entities.ingredientsUnits.fields.parentUnitType',
    ),
    schema: schemas.string(
      i18n(
        'entities.ingredientsUnits.fields.parentUnitType',
      ),
      {},
    ),
  },
];
