import IngredientService from 'src/modules/ingredient/ingredientService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'INGREDIENT_VIEW';

const ingredientViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: ingredientViewActions.FIND_STARTED,
      });

      const record = await IngredientService.find(id);

      dispatch({
        type: ingredientViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: ingredientViewActions.FIND_ERROR,
      });

      getHistory().push('/ingredient');
    }
  },
};

export default ingredientViewActions;
