import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/ingredientsSuppliers/importer/ingredientsSuppliersImporterSelectors';
import IngredientsSuppliersService from 'src/modules/ingredientsSuppliers/ingredientsSuppliersService';
import fields from 'src/modules/ingredientsSuppliers/importer/ingredientsSuppliersImporterFields';
import { i18n } from 'src/i18n';

const ingredientsSuppliersImporterActions = importerActions(
  'INGREDIENTSSUPPLIERS_IMPORTER',
  selectors,
  IngredientsSuppliersService.import,
  fields,
  i18n('entities.ingredientsSuppliers.importer.fileName'),
);

export default ingredientsSuppliersImporterActions;
