import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/unit/importer/unitImporterSelectors';
import UnitService from 'src/modules/unit/unitService';
import fields from 'src/modules/unit/importer/unitImporterFields';
import { i18n } from 'src/i18n';

const unitImporterActions = importerActions(
  'UNIT_IMPORTER',
  selectors,
  UnitService.import,
  fields,
  i18n('entities.unit.importer.fileName'),
);

export default unitImporterActions;
