import list from 'src/modules/lineBuildMenuItemMapping/list/lineBuildMenuItemMappingListReducers';
import form from 'src/modules/lineBuildMenuItemMapping/form/lineBuildMenuItemMappingFormReducers';
import view from 'src/modules/lineBuildMenuItemMapping/view/lineBuildMenuItemMappingViewReducers';
import destroy from 'src/modules/lineBuildMenuItemMapping/destroy/lineBuildMenuItemMappingDestroyReducers';
import importerReducer from 'src/modules/lineBuildMenuItemMapping/importer/lineBuildMenuItemMappingImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
