import list from 'src/modules/recipeItem/list/recipeItemListReducers';
import form from 'src/modules/recipeItem/form/recipeItemFormReducers';
import view from 'src/modules/recipeItem/view/recipeItemViewReducers';
import destroy from 'src/modules/recipeItem/destroy/recipeItemDestroyReducers';
import importerReducer from 'src/modules/recipeItem/importer/recipeItemImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
