// Place the URL here with the /api suffix.
// Ex.:`https://domain.com/api`;

// const backendUrl = `https://backend-ljxwp4zvba-nw.a.run.app/api`;
// const backendUrl = `https://tfl-tuna-backend-env.eba-rfnpvrmv.eu-west-2.elasticbeanstalk.com/api`; 

// const backendUrl = "https://d1dw0mn4gz3hhe.cloudfront.net/api"; 
const backendUrl = "https://api-tuna.thefoodlab.com/api"; 

// SwaggerUI Documentation URL
// Leave black if documentation should be hidden
const apiDocumentationUrl = `/documentation`;

/**
 * Frontend URL.
 */
const frontendUrl = {
  host: 'tuna.thefoodlab.com',
  protocol: 'https',
};

/**
 * Tenant Mode
 * multi: Allow new users to create new tenants.
 * multi-with-subdomain: Same as multi, but enable access to the tenant via subdomain.
 * single: One tenant, the first user to register will be the admin.
 */
const tenantMode = 'multi';

/**
 * Plan payments configuration.
 */
const isPlanEnabled = true;
const stripePublishableKey = '';

export default {
  frontendUrl,
  backendUrl,
  apiDocumentationUrl,
  tenantMode,
  isPlanEnabled,
  stripePublishableKey,
};
