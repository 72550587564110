import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.marketBrand.fields.name'),
    schema: schemas.string(
      i18n('entities.marketBrand.fields.name'),
      {},
    ),
  },
  {
    name: 'code',
    label: i18n('entities.marketBrand.fields.code'),
    schema: schemas.string(
      i18n('entities.marketBrand.fields.code'),
      {},
    ),
  },
];
