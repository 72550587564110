import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import ingredientEnumerators from 'src/modules/ingredient/ingredientEnumerators';

export default [
  {
    name: 'id',
    label: i18n('entities.ingredient.fields.id'),
    schema: schemas.string(
      i18n('entities.ingredient.fields.id'),
      {},
    ),
  },
  {
    name: 'name',
    label: i18n('entities.ingredient.fields.name'),
    schema: schemas.string(
      i18n('entities.ingredient.fields.name'),
      {},
    ),
  },
  {
    name: 'arabicName',
    label: i18n('entities.ingredient.fields.arabicName'),
    schema: schemas.string(
      i18n('entities.ingredient.fields.arabicName'),
      {},
    ),
  },
  {
    name: 'sku',
    label: i18n('entities.ingredient.fields.sku'),
    schema: schemas.string(
      i18n('entities.ingredient.fields.sku'),
      {},
    ),
  },
  {
    name: 'storageType',
    label: i18n('entities.ingredient.fields.storageType'),
    schema: schemas.relationToOne(
      i18n('entities.ingredient.fields.storageType'),
      {},
    ),
  },
  {
    name: 'category',
    label: i18n('entities.ingredient.fields.category'),
    schema: schemas.relationToOne(
      i18n('entities.ingredient.fields.category'),
      {},
    ),
  },
  {
    name: 'marketBrand',
    label: i18n('entities.ingredient.fields.marketBrand'),
    schema: schemas.relationToOne(
      i18n('entities.ingredient.fields.marketBrand'),
      {},
    ),
  },
  {
    name: 'conversionFactor',
    label: i18n(
      'entities.ingredient.fields.conversionFactor',
    ),
    schema: schemas.decimal(
      i18n('entities.ingredient.fields.conversionFactor'),
      {},
    ),
  },
  {
    name: 'usageUnit',
    label: i18n('entities.ingredient.fields.usageUnit'),
    schema: schemas.enumerator(
      i18n('entities.ingredient.fields.usageUnit'),
      {
        options: ingredientEnumerators.usageUnit,
      },
    ),
  },
  {
    name: 'ingredientYield',
    label: i18n(
      'entities.ingredient.fields.ingredientYield',
    ),
    schema: schemas.string(
      i18n('entities.ingredient.fields.ingredientYield'),
      {},
    ),
  },
  {
    name: 'isSellable',
    label: i18n('entities.ingredient.fields.isSellable'),
    schema: schemas.string(
      i18n('entities.ingredient.fields.isSellable'),
      {},
    ),
  },
  {
    name: 'purchasePackageUnit',
    label: 'Unit',
    schema: schemas.string(
      i18n(
        'entities.ingredient.fields.purchasePackageUnit',
      ),
      {},
    ),
  },
];
