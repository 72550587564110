import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'sku',
    label: i18n('entities.productionPlan.fields.sku'),
    schema: schemas.string(
      i18n('entities.productionPlan.fields.sku'),
      {},
    ),
  },
  {
    name: 'qtyRequired',
    label: i18n('entities.productionPlan.fields.qtyRequired'),
    schema: schemas.string(
      i18n('entities.productionPlan.fields.qtyRequired'),
      {},
    ),
  },
  {
    name: 'location',
    label: i18n('entities.productionPlan.fields.location'),
    schema: schemas.string(
      i18n('entities.productionPlan.fields.location'),
      {},
    ),
  },
  {
    name: 'expectedDate',
    label: i18n('entities.productionPlan.fields.expectedDate'),
    schema: schemas.date(
      i18n('entities.productionPlan.fields.expectedDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
];