import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.item.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.item.fields.name'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'itemQty',
    label: i18n('entities.item.fields.itemQty'),
  },
  {
    name: 'itemUnit',
    label: i18n('entities.item.fields.itemUnit'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.item.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.item.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'batchRecipe',
    label: i18n('entities.item.fields.batchRecipe'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'preferredBrand',
    label: i18n('entities.item.fields.preferredBrand'),
  },
  {
    name: 'alternativeBrand',
    label: i18n('entities.item.fields.alternativeBrand'),
  },
  {
    name: 'prepNote',
    label: i18n('entities.item.fields.prepNote'),
  },
];
