import list from 'src/modules/ingredientsSuppliers/list/ingredientsSuppliersListReducers';
import form from 'src/modules/ingredientsSuppliers/form/ingredientsSuppliersFormReducers';
import view from 'src/modules/ingredientsSuppliers/view/ingredientsSuppliersViewReducers';
import destroy from 'src/modules/ingredientsSuppliers/destroy/ingredientsSuppliersDestroyReducers';
import importerReducer from 'src/modules/ingredientsSuppliers/importer/ingredientsSuppliersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
