import list from 'src/modules/bpLabs/list/bpLabsListReducers';
import form from 'src/modules/bpLabs/form/bpLabsFormReducers';
import view from 'src/modules/bpLabs/view/bpLabsViewReducers';
import destroy from 'src/modules/bpLabs/destroy/bpLabsDestroyReducers';
import importerReducer from 'src/modules/bpLabs/importer/bpLabsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
