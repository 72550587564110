import list from 'src/modules/brandMenuCategory/list/brandMenuCategoryListReducers';
import form from 'src/modules/brandMenuCategory/form/brandMenuCategoryFormReducers';
import view from 'src/modules/brandMenuCategory/view/brandMenuCategoryViewReducers';
import destroy from 'src/modules/brandMenuCategory/destroy/brandMenuCategoryDestroyReducers';
import importerReducer from 'src/modules/brandMenuCategory/importer/brandMenuCategoryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
