import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.id',
    ),
  },
  {
    name: 'lineBuildName',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.lineBuildName',
    ),
  },
  {
    name: 'menuItemName',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.menuItemName',
    ),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.lineBuildMenuItemMapping.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
