import { i18n } from 'src/i18n';

class Roles {
  static get values() {
    return {
      admin: 'admin',
      onboarding: 'onboarding',
      onboardingManager: 'onboardingManager',
      ownsBrandManager:"ownsBrandManager",
      ownsBrandAssociate: "ownsBrandAssociate",
      finance: 'finance/cost_control',
      financeManager: 'financeManager',
      ops: 'ops',
      opsManager: 'opsManager',
      kitchen: "kitchen", 
      procurement: 'procurement',
      procurementManager: 'procurementManager',
      aggregator: 'aggregator',
      read: 'read',
      custom: 'custom',
    };
  }

  static get getOwnBrands(){
    return {
      ownsBrandManager: "ownsBrandManager",
      ownsBrandAssociate: "ownsBrandAssociate",
    };
  };

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }
}

export default Roles;
