import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

const relationToManyFn = (recipes, items, field) => {
  return (value) => {
    return (value || [])
      .map((item) => {
        if (field === 'items') {
          if (item?.ingredientId) {
            return (
              items.find(
                (it) => it?.id === item?.ingredientId,
              )?.name +
              `/${item?.itemQty}/${item?.itemUnit}/${
                item?.preferredBrand || 'null'
              }/${item?.itemYield || 'null'}`
            );
          } else if (item?.batchRecipeId) {
            return (
              recipes?.find(
                (recipe) =>
                  recipe?.id === item?.batchRecipeId,
              )?.name +
              `/${item?.itemQty}/${item?.itemUnit}/${
                item?.preferredBrand || 'null'
              }/${item?.itemYield || 'null'}`
            );
          }
        } else {
          return item?.prepNote;
        }
      })
      .join(', ');
  };
};

export const exporterFieldsFn = (recipes, ingredients) => {
  return [
    {
      name: 'id',
      label: i18n('entities.lineBuild.fields.id'),
    },
    {
      name: 'menuItemName',
      label: i18n('entities.lineBuild.fields.menuItemName'),
    },
    {
      name: 'recipeQty',
      label: i18n('entities.lineBuild.fields.recipeQty'),
    },
    {
      name: 'recipeUnit',
      label: i18n('entities.lineBuild.fields.recipeUnit'),
    },
    {
      name: 'items',
      label: i18n('entities.lineBuild.fields.items'),
      render: relationToManyFn(
        recipes,
        ingredients,
        'items',
      ),
    },
    {
      name: 'allergens',
      label: i18n('entities.lineBuild.fields.allergens'),
      // render: exporterRenders.stringArray(),
    },
    {
      name: 'methods',
      label: i18n('entities.lineBuild.fields.methods'),
    },
    {
      name: 'images',
      label: i18n('entities.lineBuild.fields.images'),
      render: exporterRenders.filesOrImages(),
    },
    {
      name: 'arMenuItemName',
      label: i18n(
        'entities.lineBuild.fields.arMenuItemName',
      ),
    },
    {
      name: 'packagingInstructions',
      label: i18n(
        'entities.lineBuild.fields.packagingInstructions',
      ),
    },
    {
      name: 'packagingImages',
      label: i18n(
        'entities.lineBuild.fields.packagingImages',
      ),
      render: exporterRenders.filesOrImages(),
    },
    {
      name: 'addons',
      label: i18n('entities.lineBuild.fields.addons'),
      render: exporterRenders.stringArray(),
    },
    {
      name: 'isAddOn',
      label: i18n('entities.lineBuild.fields.isAddOn'),
    },
    {
      name: 'isExtra',
      label: i18n('entities.lineBuild.fields.isExtra'),
    },
    {
      name: 'createdAt',
      label: i18n('entities.lineBuild.fields.createdAt'),
      render: exporterRenders.datetime(),
    },
    {
      name: 'updatedAt',
      label: i18n('entities.lineBuild.fields.updatedAt'),
      render: exporterRenders.datetime(),
    },
  ];
};

export default [
  {
    name: 'id',
    label: i18n('entities.lineBuild.fields.id'),
  },
  {
    name: 'menuItemName',
    label: i18n('entities.lineBuild.fields.menuItemName'),
  },
  {
    name: 'recipeQty',
    label: i18n('entities.lineBuild.fields.recipeQty'),
  },
  {
    name: 'recipeUnit',
    label: i18n('entities.lineBuild.fields.recipeUnit'),
  },
  {
    name: 'items',
    label: i18n('entities.lineBuild.fields.items'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'allergens',
    label: i18n('entities.lineBuild.fields.allergens'),
    // render: exporterRenders.stringArray(),
  },
  {
    name: 'methods',
    label: i18n('entities.lineBuild.fields.methods'),
  },
  {
    name: 'images',
    label: i18n('entities.lineBuild.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'arMenuItemName',
    label: i18n('entities.lineBuild.fields.arMenuItemName'),
  },
  {
    name: 'packagingInstructions',
    label: i18n(
      'entities.lineBuild.fields.packagingInstructions',
    ),
  },
  {
    name: 'packagingImages',
    label: i18n(
      'entities.lineBuild.fields.packagingImages',
    ),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'menuCategoryName',
    label: i18n(
      'entities.lineBuild.fields.menuCategoryName',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'addons',
    label: i18n('entities.lineBuild.fields.addons'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'isAddOn',
    label: i18n('entities.lineBuild.fields.isAddOn'),
  },
  {
    name: 'isExtra',
    label: i18n('entities.lineBuild.fields.isExtra'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.lineBuild.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.lineBuild.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
