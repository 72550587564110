import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/brandMenuItem/importer/brandMenuItemImporterSelectors';
import BrandMenuItemService from 'src/modules/brandMenuItem/brandMenuItemService';
import fields from 'src/modules/brandMenuItem/importer/brandMenuItemImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'BRANDMENUITEM_IMPORTER',
  selectors,
  BrandMenuItemService.import,
  fields,
  i18n('entities.brandMenuItem.importer.fileName'),
);
