import LabStocksService from 'src/modules/labStocks/labStocksService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'LABSTOCKS_VIEW';

const labStocksViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: labStocksViewActions.FIND_STARTED,
      });

      const record = await LabStocksService.find(id);

      dispatch({
        type: labStocksViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: labStocksViewActions.FIND_ERROR,
      });

      getHistory().push('/lab-stocks');
    }
  },
};

export default labStocksViewActions;
