import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'mo',
    label: i18n('entities.productionOrder.fields.mo'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.mo'),
      {},
    ),
  },
  {
    name: 'sku',
    label: i18n('entities.productionOrder.fields.sku'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.sku'),
      {},
    ),
  },
  {
    name: 'batchName',
    label: i18n('entities.productionOrder.fields.batchName'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.batchName'),
      {},
    ),
  },
  {
    name: 'qtyRequested',
    label: i18n('entities.productionOrder.fields.qtyRequested'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.qtyRequested'),
      {},
    ),
  },
  {
    name: 'moq',
    label: i18n('entities.productionOrder.fields.moq'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.moq'),
      {},
    ),
  },
  {
    name: 'expectedDate',
    label: i18n('entities.productionOrder.fields.expectedDate'),
    schema: schemas.date(
      i18n('entities.productionOrder.fields.expectedDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'location',
    label: i18n('entities.productionOrder.fields.location'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.location'),
      {},
    ),
  },
  {
    name: 'qtyProduced',
    label: i18n('entities.productionOrder.fields.qtyProduced'),
    schema: schemas.string(
      i18n('entities.productionOrder.fields.qtyProduced'),
      {},
    ),
  },
];