import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.cutting.fields.name'),
    schema: schemas.string(
      i18n('entities.cutting.fields.name'),
      {},
    ),
  },
  {
    name: 'cuttingImage',
    label: i18n('entities.cutting.fields.cuttingImage'),
    schema: schemas.images(
      i18n('entities.cutting.fields.cuttingImage'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.cutting.fields.description'),
    schema: schemas.string(
      i18n('entities.cutting.fields.description'),
      {},
    ),
  },
  {
    name: 'arabicName',
    label: i18n('entities.cutting.fields.arabicName'),
    schema: schemas.string(
      i18n('entities.cutting.fields.arabicName'),
      {},
    ),
  },
];
