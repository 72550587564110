import list from 'src/modules/type/list/storageTypeListReducers';
import form from 'src/modules/type/form/storageTypeFormReducers';
import view from 'src/modules/type/view/storageTypeViewReducers';
import destroy from 'src/modules/type/destroy/storageTypeDestroyReducers';
import importerReducer from 'src/modules/type/importer/storageTypeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
