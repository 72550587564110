import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/supplyHistory/importer/supplyHistoryImporterSelectors';
import SupplyHistoryService from 'src/modules/supplyHistory/supplyHistoryService';
import fields from 'src/modules/supplyHistory/importer/supplyHistoryImporterFields';
import { i18n } from 'src/i18n';

const supplyHistoryImporterActions = importerActions(
  'SUPPLYHISTORY_IMPORTER',
  selectors,
  SupplyHistoryService.import,
  fields,
  i18n('entities.supplyHistory.importer.fileName'),
);

export default supplyHistoryImporterActions;
