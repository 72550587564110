import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/menuItem/importer/menuItemImporterSelectors';
import MenuItemService from 'src/modules/menuItem/menuItemService';
import fields from 'src/modules/menuItem/importer/menuItemImporterFields';
import { i18n } from 'src/i18n';

const menuItemImporterActions = importerActions(
  'MENUITEM_IMPORTER',
  selectors,
  MenuItemService.import,
  fields,
  i18n('entities.menuItem.importer.fileName'),
);

export default menuItemImporterActions;
