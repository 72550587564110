import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.comboItem.fields.name'),
    schema: schemas.string(
      i18n('entities.comboItem.fields.name'),
      {},
    ),
  },
  {
    name: 'ingredient',
    label: i18n('entities.comboItem.fields.ingredient'),
    schema: schemas.relationToMany(
      i18n('entities.comboItem.fields.ingredient'),
      {},
    ),
  },
  {
    name: 'brandmenuitem',
    label: i18n('entities.comboItem.fields.brandmenuitem'),
    schema: schemas.relationToMany(
      i18n('entities.comboItem.fields.brandmenuitem'),
      {},
    ),
  },
  {
    name: 'minchoice',
    label: i18n('entities.comboItem.fields.minchoice'),
    schema: schemas.integer(
      i18n('entities.comboItem.fields.minchoice'),
      {},
    ),
  },
  {
    name: 'maxchoice',
    label: i18n('entities.comboItem.fields.maxchoice'),
    schema: schemas.integer(
      i18n('entities.comboItem.fields.maxchoice'),
      {},
    ),
  },
  {
    name: 'image',
    label: i18n('entities.comboItem.fields.image'),
    schema: schemas.images(
      i18n('entities.comboItem.fields.image'),
      {},
    ),
  },
];