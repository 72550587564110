import list from 'src/modules/draftLineBuild/list/lineBuildListReducers';
import form from 'src/modules/draftLineBuild/form/lineBuildFormReducers';
import view from 'src/modules/draftLineBuild/view/lineBuildViewReducers';
import destroy from 'src/modules/draftLineBuild/destroy/lineBuildDestroyReducers';
import importerReducer from 'src/modules/draftLineBuild/importer/lineBuildImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
