import list from 'src/modules/labs/list/labsListReducers';
import form from 'src/modules/labs/form/labsFormReducers';
import view from 'src/modules/labs/view/labsViewReducers';
import destroy from 'src/modules/labs/destroy/labsDestroyReducers';
import importerReducer from 'src/modules/labs/importer/labsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
