import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'id',
    label: i18n('entities.lineBuild.fields.id'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.id'),
      {},
    ),
  },
  {
    name: 'menuItemName',
    label: i18n('entities.lineBuild.fields.menuItemName'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.menuItemName'),
      {},
    ),
  },
  {
    name: 'recipeQty',
    label: i18n('entities.lineBuild.fields.recipeQty'),
    schema: schemas.integer(
      i18n('entities.lineBuild.fields.recipeQty'),
      {},
    ),
  },
  {
    name: 'recipeUnit',
    label: i18n('entities.lineBuild.fields.recipeUnit'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.recipeUnit'),
      {},
    ),
  },
  {
    name: 'items',
    label: i18n('entities.lineBuild.fields.items'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.items'),
      {},
    ),
  },
  {
    name: 'allergens',
    label: i18n('entities.lineBuild.fields.allergens'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.allergens'),
      {},
    ),
  },
  {
    name: 'methods',
    label: i18n('entities.lineBuild.fields.methods'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.methods'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.lineBuild.fields.images'),
    schema: schemas.images(
      i18n('entities.lineBuild.fields.images'),
      {},
    ),
  },
  {
    name: 'arMenuItemName',
    label: i18n('entities.lineBuild.fields.arMenuItemName'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.arMenuItemName'),
      {},
    ),
  },

  {
    name: 'packagingInstructions',
    label: i18n(
      'entities.lineBuild.fields.packagingInstructions',
    ),
    schema: schemas.string(
      i18n(
        'entities.lineBuild.fields.packagingInstructions',
      ),
      {},
    ),
  },
  {
    name: 'packagingImages',
    label: i18n(
      'entities.lineBuild.fields.packagingImages',
    ),
    schema: schemas.images(
      i18n('entities.lineBuild.fields.packagingImages'),
      {},
    ),
  },
  {
    name: 'menuCategoryName',
    label: i18n(
      'entities.lineBuild.fields.menuCategoryName',
    ),
    schema: schemas.relationToOne(
      i18n('entities.lineBuild.fields.menuCategoryName'),
      {},
    ),
  },
  {
    name: 'addons',
    label: i18n('entities.lineBuild.fields.addons'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.addons'),
      {},
    ),
  },
  {
    name: 'isAddOn',
    label: i18n('entities.lineBuild.fields.isAddOn'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.isAddOn'),
      {},
    ),
  },
  {
    name: 'isExtra',
    label: i18n('entities.lineBuild.fields.isExtra'),
    schema: schemas.string(
      i18n('entities.lineBuild.fields.isExtra'),
      {},
    ),
  },
];
