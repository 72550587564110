import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/bpCode/importer/bpCodeImporterSelectors';
import BpCodeService from 'src/modules/bpCode/bpCodeService';
import fields from 'src/modules/bpCode/importer/bpCodeImporterFields';
import { i18n } from 'src/i18n';

const bpCodeImporterActions = importerActions(
  'BPCODE_IMPORTER',
  selectors,
  BpCodeService.import,
  fields,
  i18n('entities.bpCode.importer.fileName'),
);

export default bpCodeImporterActions;
