import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'labs',
    label: i18n('entities.bpLabs.fields.labs'),
    schema: schemas.relationToMany(
      i18n('entities.bpLabs.fields.labs'),
      {},
    ),
  },
];
