import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.ingredientsUnits.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.ingredientsUnits.fields.name'),
  },
  {
    name: 'symbol',
    label: i18n('entities.ingredientsUnits.fields.symbol'),
  },
  {
    name: 'isbaseunit',
    label: i18n(
      'entities.ingredientsUnits.fields.isbaseunit',
    ),
    render: exporterRenders.boolean(),
  },
  {
    name: 'conversion',
    label: i18n(
      'entities.ingredientsUnits.fields.conversion',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: 'parentUnitType',
    label: i18n(
      'entities.ingredientsUnits.fields.parentUnitType',
    ),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.ingredientsUnits.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.ingredientsUnits.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
