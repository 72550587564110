import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'name',
    label: i18n('entities.item.fields.name'),
    schema: schemas.relationToOne(
      i18n('entities.item.fields.name'),
      {},
    ),
  },
  {
    name: 'itemQty',
    label: i18n('entities.item.fields.itemQty'),
    schema: schemas.integer(
      i18n('entities.item.fields.itemQty'),
      {},
    ),
  },
  {
    name: 'itemUnit',
    label: i18n('entities.item.fields.itemUnit'),
    schema: schemas.string(
      i18n('entities.item.fields.itemUnit'),
      {},
    ),
  },
  {
    name: 'batchRecipe',
    label: i18n('entities.item.fields.batchRecipe'),
    schema: schemas.relationToOne(
      i18n('entities.item.fields.batchRecipe'),
      {},
    ),
  },
  {
    name: 'preferredBrand',
    label: i18n('entities.item.fields.preferredBrand'),
    schema: schemas.string(
      i18n('entities.item.fields.preferredBrand'),
      {},
    ),
  },
  {
    name: 'alternativeBrand',
    label: i18n('entities.item.fields.alternativeBrand'),
    schema: schemas.string(
      i18n('entities.item.fields.alternativeBrand'),
      {},
    ),
  },
  {
    name: 'prepNote',
    label: i18n('entities.item.fields.prepNote'),
    schema: schemas.string(
      i18n('entities.item.fields.prepNote'),
      {},
    ),
  },
];
