import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.comboItem.fields.id'),
  },
  {
    name: 'name',
    label: i18n('entities.comboItem.fields.name'),
  },
  {
    name: 'ingredient',
    label: i18n('entities.comboItem.fields.ingredient'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'brandmenuitem',
    label: i18n('entities.comboItem.fields.brandmenuitem'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'minchoice',
    label: i18n('entities.comboItem.fields.minchoice'),
  },
  {
    name: 'maxchoice',
    label: i18n('entities.comboItem.fields.maxchoice'),
  },
  {
    name: 'image',
    label: i18n('entities.comboItem.fields.image'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.comboItem.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.comboItem.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
