import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/brandMenuCategory/importer/brandMenuCategoryImporterSelectors';
import BrandMenuCategoryService from 'src/modules/brandMenuCategory/brandMenuCategoryService';
import fields from 'src/modules/brandMenuCategory/importer/brandMenuCategoryImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'BRANDMENUCATEGORY_IMPORTER',
  selectors,
  BrandMenuCategoryService.import,
  fields,
  i18n('entities.brandMenuCategory.importer.fileName'),
);
