import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/comboItem/importer/comboItemImporterSelectors';
import ComboItemService from 'src/modules/comboItem/comboItemService';
import fields from 'src/modules/comboItem/importer/comboItemImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'COMBOITEM_IMPORTER',
  selectors,
  ComboItemService.import,
  fields,
  i18n('entities.comboItem.importer.fileName'),
);
