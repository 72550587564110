import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'lab',
    label: i18n('entities.labStocks.fields.lab'),
    schema: schemas.relationToOne(
      i18n('entities.labStocks.fields.lab'),
      {},
    ),
  },
  {
    name: 'ingredient',
    label: i18n('entities.labStocks.fields.ingredient'),
    schema: schemas.relationToOne(
      i18n('entities.labStocks.fields.ingredient'),
      {},
    ),
  },
  {
    name: 'amount',
    label: i18n('entities.labStocks.fields.amount'),
    schema: schemas.decimal(
      i18n('entities.labStocks.fields.amount'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.labStocks.fields.unit'),
    schema: schemas.relationToOne(
      i18n('entities.labStocks.fields.unit'),
      {},
    ),
  },
];
