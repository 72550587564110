import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import brandMenuItemEnumerators from 'src/modules/brandMenuItem/brandMenuItemEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.brandMenuItem.fields.name'),
    schema: schemas.string(
      i18n('entities.brandMenuItem.fields.name'),
      {},
    ),
  },
  {
    name: 'linebuild',
    label: i18n('entities.brandMenuItem.fields.linebuild'),
    schema: schemas.relationToOne(
      i18n('entities.brandMenuItem.fields.linebuild'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.brandMenuItem.fields.description'),
    schema: schemas.string(
      i18n('entities.brandMenuItem.fields.description'),
      {},
    ),
  },
  {
    name: 'customerRequests',
    label: i18n('entities.brandMenuItem.fields.customerRequests'),
    schema: schemas.relationToMany(
      i18n('entities.brandMenuItem.fields.customerRequests'),
      {},
    ),
  },
  {
    name: 'price',
    label: i18n('entities.brandMenuItem.fields.price'),
    schema: schemas.decimal(
      i18n('entities.brandMenuItem.fields.price'),
      {},
    ),
  },
  {
    name: 'isVat',
    label: i18n('entities.brandMenuItem.fields.isVat'),
    schema: schemas.boolean(
      i18n('entities.brandMenuItem.fields.isVat'),
      {},
    ),
  },
  {
    name: 'vatpercentage',
    label: i18n('entities.brandMenuItem.fields.vatpercentage'),
    schema: schemas.decimal(
      i18n('entities.brandMenuItem.fields.vatpercentage'),
      {},
    ),
  },
  {
    name: 'image',
    label: i18n('entities.brandMenuItem.fields.image'),
    schema: schemas.images(
      i18n('entities.brandMenuItem.fields.image'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.brandMenuItem.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.brandMenuItem.fields.status'),
      {
        "options": brandMenuItemEnumerators.status
      },
    ),
  },
  {
    name: 'brandMenuCategory',
    label: i18n('entities.brandMenuItem.fields.brandMenuCategory'),
    schema: schemas.relationToOne(
      i18n('entities.brandMenuItem.fields.brandMenuCategory'),
      {},
    ),
  },
];