import authAxios from 'src/modules/shared/axios/authAxios';
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant';

export default class RecipeItemService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/${id}`,
      body,
    );

    return response.data;
  }

  static async updateRecipeCentralFacility(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/central-facility/${id}`,
      body,
    );

    return response.data;
  };

  static async updateRecipeArchived(id, data) {
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/archived/${id}`,
      body,
    );

    return response.data;
  };

  static async updateUniqueBatch(id, data){
    const body = {
      id,
      data,
    };

    let tenantId = data?.tenantId; 

    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/unique-batch/${id}`,
      body,
    );

    return response.data;
  
  };

  static async updateBatchUnitCost(id, data){
    const body = {
      id,
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/update-batch-unit-cost/${id}`,
      body,
    );

    return response.data;
  
  };

  static async shareBatch(id, data){
    const body = {
      id,
      data,
    };
    
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/share-batch/${id}`,
      body,
    );

    return response.data;
  };

  static async updateBatchSKU(id, data){
    const body = {
      id,
      data,
    };
    
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.put(
      `/tenant/${tenantId}/recipe-item/update-batch-sku/${id}`,
      body,
    );

    return response.data;
  };

  static async destroyAll(ids) {
    const params = {
      ids,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.delete(
      `/tenant/${tenantId}/recipe-item`,
      {
        params,
      },
    );

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/recipe-item`,
      body,
    );

    return response.data;
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/recipe-item/import`,
      body,
    );

    return response.data;
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/recipe-item/${id}`,
    );

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/recipe-item`,
      {
        params,
      },
    );

    return response.data;
  }

  static async findAllCommonBatches() {
    const params = {};
    const tenantId = AuthCurrentTenant.get();
    const response = await authAxios.get(
      `/tenant/${tenantId}/common-batches`,
      {
        params,
      },
    );
    return response?.data;
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.get(
      `/tenant/${tenantId}/recipe-item/autocomplete`,
      {
        params,
      },
    );

    return response.data;
  }

  static async createPDF(data) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/recipe-item/create-pdf`,
      body,
      { responseType: 'blob' },
    );

    return response.data;
  }

  static async copyToDraftBatches(data={}) {
    const body = {
      data,
    };

    const tenantId = AuthCurrentTenant.get();

    const response = await authAxios.post(
      `/tenant/${tenantId}/recipe-item/copy-to-draft-recipe-item`,
      body,
    );

    return response.data;
  }

}
