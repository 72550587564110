import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/customerRequest/importer/customerRequestImporterSelectors';
import CustomerRequestService from 'src/modules/customerRequest/customerRequestService';
import fields from 'src/modules/customerRequest/importer/customerRequestImporterFields';
import { i18n } from 'src/i18n';

export default importerActions(
  'CUSTOMERREQUEST_IMPORTER',
  selectors,
  CustomerRequestService.import,
  fields,
  i18n('entities.customerRequest.importer.fileName'),
);
