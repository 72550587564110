import list from 'src/modules/ingredient/list/ingredientListReducers';
import form from 'src/modules/ingredient/form/ingredientFormReducers';
import view from 'src/modules/ingredient/view/ingredientViewReducers';
import destroy from 'src/modules/ingredient/destroy/ingredientDestroyReducers';
import importerReducer from 'src/modules/ingredient/importer/ingredientImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
