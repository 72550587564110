import list from 'src/modules/marketBrand/list/marketBrandListReducers';
import form from 'src/modules/marketBrand/form/marketBrandFormReducers';
import view from 'src/modules/marketBrand/view/marketBrandViewReducers';
import destroy from 'src/modules/marketBrand/destroy/marketBrandDestroyReducers';
import importerReducer from 'src/modules/marketBrand/importer/marketBrandImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
