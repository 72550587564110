import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.purchaseOrder.fields.id'),
  },
  {
    name: 'sku',
    label: i18n('entities.purchaseOrder.fields.sku'),
  },
  {
    name: 'itemName',
    label: i18n('entities.purchaseOrder.fields.itemName'),
  },
  {
    name: 'qty',
    label: i18n('entities.purchaseOrder.fields.qty'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'unit',
    label: i18n('entities.purchaseOrder.fields.unit'),
  },
  {
    name: 'cost',
    label: i18n('entities.purchaseOrder.fields.cost'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'vATPercentage',
    label: i18n(
      'entities.purchaseOrder.fields.vATPercentage',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: 'vat',
    label: i18n('entities.purchaseOrder.fields.vat'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'total',
    label: i18n('entities.purchaseOrder.fields.total'),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.purchaseOrder.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.purchaseOrder.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'date',
    label: i18n('entities.purchaseOrder.fields.date'),
  },
];
