import list from 'src/modules/lineBuild/list/lineBuildListReducers';
import form from 'src/modules/lineBuild/form/lineBuildFormReducers';
import view from 'src/modules/lineBuild/view/lineBuildViewReducers';
import destroy from 'src/modules/lineBuild/destroy/lineBuildDestroyReducers';
import importerReducer from 'src/modules/lineBuild/importer/lineBuildImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
