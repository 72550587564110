import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'item',
    label: i18n('entities.skuIngredientsOp.fields.item'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.item'),
      {},
    ),
  },
  {
    name: 'itemAr',
    label: i18n('entities.skuIngredientsOp.fields.itemAr'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.itemAr'),
      {},
    ),
  },
  {
    name: 'category',
    label: i18n('entities.skuIngredientsOp.fields.category'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.category'),
      {},
    ),
  },
  {
    name: 'sku',
    label: i18n('entities.skuIngredientsOp.fields.sku'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.sku'),
      {},
    ),
  },
  {
    name: 'unit',
    label: i18n('entities.skuIngredientsOp.fields.unit'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.unit'),
      {},
    ),
  },
  {
    name: 'root',
    label: i18n('entities.skuIngredientsOp.fields.root'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.root'),
      {},
    ),
  },
  {
    name: 'storage',
    label: i18n('entities.skuIngredientsOp.fields.storage'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.storage'),
      {},
    ),
  },
  {
    name: 'desc',
    label: i18n('entities.skuIngredientsOp.fields.desc'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.desc'),
      {},
    ),
  },
  {
    name: 'brand',
    label: i18n('entities.skuIngredientsOp.fields.brand'),
    schema: schemas.string(
      i18n('entities.skuIngredientsOp.fields.brand'),
      {},
    ),
  },
];