import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/labs/importer/labsImporterSelectors';
import LabsService from 'src/modules/labs/labsService';
import fields from 'src/modules/labs/importer/labsImporterFields';
import { i18n } from 'src/i18n';

const labsImporterActions = importerActions(
  'LABS_IMPORTER',
  selectors,
  LabsService.import,
  fields,
  i18n('entities.labs.importer.fileName'),
);

export default labsImporterActions;
