import IngredientsSuppliersService from 'src/modules/ingredientsSuppliers/ingredientsSuppliersService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'INGREDIENTSSUPPLIERS_VIEW';

const ingredientsSuppliersViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: ingredientsSuppliersViewActions.FIND_STARTED,
      });

      const record = await IngredientsSuppliersService.find(
        id,
      );

      dispatch({
        type: ingredientsSuppliersViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: ingredientsSuppliersViewActions.FIND_ERROR,
      });

      getHistory().push('/ingredients-suppliers');
    }
  },
};

export default ingredientsSuppliersViewActions;
