import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.ingredientsSuppliers.fields.id'),
  },
  {
    name: 'ingredient',
    label: i18n(
      'entities.ingredientsSuppliers.fields.ingredient',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'supplier',
    label: i18n(
      'entities.ingredientsSuppliers.fields.supplier',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'cost',
    label: i18n(
      'entities.ingredientsSuppliers.fields.cost',
    ),
    render: exporterRenders.decimal(),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.ingredientsSuppliers.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.ingredientsSuppliers.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
];
