import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'request',
    label: i18n('entities.customerRequest.fields.request'),
    schema: schemas.string(
      i18n('entities.customerRequest.fields.request'),
      {},
    ),
  },
];