import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/menuCategory/importer/menuCategoryImporterSelectors';
import MenuCategoryService from 'src/modules/menuCategory/menuCategoryService';
import fields from 'src/modules/menuCategory/importer/menuCategoryImporterFields';
import { i18n } from 'src/i18n';

const menuCategoryImporterActions = importerActions(
  'MENUCATEGORY_IMPORTER',
  selectors,
  MenuCategoryService.import,
  fields,
  i18n('entities.menuCategory.importer.fileName'),
);

export default menuCategoryImporterActions;
