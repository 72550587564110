const ingredientEnumerators = {
  usageUnit: ['gram', 'ml', 'piece', 'serve', 'slice', ''],
  purchaseType: ['weight', 'unit'],
  ingredientUnit: ['g', 'kg'],
  purchasePackage: [
    'can',
    'bottle',
    'jar',
    'bag',
    'packet',
    'piece',
    'box',
    'jug',
    'gallon',
  ],
};

export default ingredientEnumerators;
