import AuditLogService from 'src/modules/auditLog/auditLogService';
import selectors from 'src/modules/auditLog/auditLogSelectors';
import exporterFields from 'src/modules/auditLog/auditLogExporterFields';
import { i18n } from 'src/i18n';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';
import IngredientService from "src/modules/ingredient/ingredientService"; 
import RecipeItemService from "src/modules/recipeItem/recipeItemService";
import LineBuildItemService from "src/modules/lineBuildItem/lineBuildItemService";
import ItemService from "src/modules/item/itemService"; 

const prefix = 'AUDIT_LOG';

const getRecordName = async(record) => {
  if(record?.entityName === 'recipeItem'){
    return record?.values?.recipeName; 
  }; 
  if(record?.entityName === 'ingredient'){
    return record?.values?.name; 
  }; 
  if(record?.entityName === 'user'){
    return record?.values?.email; 
  }; 
  if(record?.entityName === 'lineBuildMenuItemMapping'){
    return record?.values?.lineBuildName + "--" + record?.values?.menuItemName; 
  }; 
  if(record?.entityName === 'lineBuild'){
    return record?.values?.menuItemName; 
  }; 
  if(record?.entityName === 'ingredient'){
    return record?.values?.name; 
  }; 
  
  if(record?.entityName === 'item'){
    if (!!record?.values?.nameId) {
      let res = await IngredientService?.find(record?.values?.nameId); 
      return res?.name || record?.values?.nameId; 
    }
    else{
      let res: any = {}; 
      if (!!record?.values?.recipeItemId) res = await RecipeItemService?.find(record?.values?.recipeItemId); 
      return res?.recipeName || record?.values?.recipeItemId || ""; 
    }
  }; 

  if(record?.entityName === 'lineBuildItem'){
    if (!!record?.values?.ingredientId) {
      let res = await IngredientService?.find(record?.values?.ingredientId); 
      return res?.name || record?.values?.nameId; 
    }
    else{
      let res: any = {}; 
      if (!!record?.values?.recipeItemId) res = await RecipeItemService?.find(record?.values?.recipeItemId); 
      return res?.recipeName || record?.values?.recipeItemId || ""; 
    }
  }; 

  return ""; 
}; 

const auditLogActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doReset: () => async (dispatch) => {
    dispatch({
      type: auditLogActions.RESETED,
    });

    dispatch(auditLogActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: auditLogActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());

      const response = await AuditLogService.fetch(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      let rows : any = []; 

      for(let i=0; i< response?.rows?.length; i++){
        let record = response?.rows[i]; 
        let name = await getRecordName(record); 
        let values = record?.values; 
        let newRecord = {
          ...record, 
          recordName: name, 
          values: {
            ...values, 
            itemName: name, 
          }
        }; 
        rows.push(newRecord); 
      }

      response.rows = !!rows?.length ? rows : response?.rows;

      new Exporter(
        exporterFields,
        i18n('auditLog.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: auditLogActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: auditLogActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: auditLogActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        auditLogActions.doFetch(filter, rawFilter, true),
      );
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: auditLogActions.SORTER_CHANGED,
      payload: sorter,
    });

    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(
      auditLogActions.doFetch(filter, rawFilter, true),
    );
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: auditLogActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await AuditLogService.fetch(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: auditLogActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: auditLogActions.FETCH_ERROR,
        });
      }
    },
};

export default auditLogActions;
