import layoutActions from 'src/modules/layout/layoutActions';
import {useEffect, useState} from "react"; 
import { useDispatch, useSelector } from 'react-redux';
import I18nSelect from 'src/view/layout/I18nSelect';
import {
  AppBar,
  Toolbar,
  IconButton,
  makeStyles,
  Button, 
} from '@material-ui/core';
import UserMenu from 'src/view/layout/UserMenu';
import { 
  WbSunny, 
  NightsStay, 
  Menu as MenuIcon, 
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { i18n } from 'src/i18n';
import selectors from 'src/modules/auth/authSelectors';
import authSelectors from 'src/modules/auth/authSelectors';

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    zIndex: theme.zIndex.drawer + 1,
  },
  logo: {
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    fontSize: '1.5em',
    color: theme.palette.getContrastText(
      theme.palette.primary.main,
    ),
    textDecoration: 'none',
  },
  grow: {
    flex: '1 1 auto',
  },
}));

function Header(props) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const logoUrl = useSelector(selectors.selectLogoUrl);

  const doToggleMenu = () => {
    dispatch(layoutActions.doToggleMenu());
  };

  const currentTenant: any = useSelector(authSelectors.selectCurrentTenant); 

  const {
    globalTheme, 
    setGlobalTheme, 
  } = props; 

  return (
    <AppBar 
      className={classes.appBar} 
      position="fixed"
      style={{
        backgroundColor: currentTenant?.settings[0]?.color || "#2196f3",
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          onClick={doToggleMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>

        <Link className={classes.logo} to="/">
          {logoUrl ? (
            <img
              src={logoUrl}
              width="80px"
              alt={i18n('app.title')}
              style={{ verticalAlign: 'middle' }}
            />
          ) : (
            <>{i18n('app.title')}</>
          )}
        </Link>
        
        <div className={classes.grow} />
        <Button
          endIcon={globalTheme === "light" ? <NightsStay /> : <WbSunny />}
          onClick={()=>{
            setGlobalTheme(globalTheme === "light" ? "dark" : "light"); 
          }}
          style={{
            color: globalTheme === "light" ? "#15202B" : "white",
            width: "5px",
          }}
          variant={
            globalTheme === "light" ? "contained" : "outlined"
          }
        >
        </Button>
        {/* <I18nSelect /> */}
        <UserMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
