import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'comboname',
    label: i18n('entities.combo.fields.comboname'),
    schema: schemas.string(
      i18n('entities.combo.fields.comboname'),
      {},
    ),
  },
  {
    name: 'comboitem',
    label: i18n('entities.combo.fields.comboitem'),
    schema: schemas.relationToMany(
      i18n('entities.combo.fields.comboitem'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.combo.fields.description'),
    schema: schemas.string(
      i18n('entities.combo.fields.description'),
      {},
    ),
  },
  {
    name: 'price',
    label: i18n('entities.combo.fields.price'),
    schema: schemas.decimal(
      i18n('entities.combo.fields.price'),
      {},
    ),
  },
];