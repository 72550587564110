import list from 'src/modules/labStocks/list/labStocksListReducers';
import form from 'src/modules/labStocks/form/labStocksFormReducers';
import view from 'src/modules/labStocks/view/labStocksViewReducers';
import destroy from 'src/modules/labStocks/destroy/labStocksDestroyReducers';
import importerReducer from 'src/modules/labStocks/importer/labStocksImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
