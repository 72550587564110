import LineBuildService from 'src/modules/draftLineBuild/lineBuildService';
import RecipeItemService from 'src/modules/draftRecipeItem/recipeItemService';
import IngredientService from 'src/modules/ingredient/ingredientService';
import selectors from 'src/modules/draftLineBuild/list/lineBuildListSelectors';
import { i18n } from 'src/i18n';
import exporterFields, {
  exporterFieldsFn,
} from 'src/modules/draftLineBuild/list/lineBuildListExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'LINEBUILD_LIST';

const lineBuildListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  doClearAllSelected() {
    return {
      type: lineBuildListActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: lineBuildListActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: lineBuildListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: lineBuildListActions.RESETED,
    });

    dispatch(lineBuildListActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: lineBuildListActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await LineBuildService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      const recipes = await RecipeItemService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );
      const ingredients = await IngredientService.list(
        {},
        null,
        null,
        null,
      );
      const formatIngredients = (ingredients) => {
        return ingredients?.map((ingredient) => {
          return {
            id: ingredient?.id,
            name: ingredient?.name,
          };
        });
      };
      const formatBatchRecipes = (recipes) => {
        return recipes?.map((recipe) => {
          return {
            id: recipe?.id,
            name: recipe?.recipeName,
          };
        });
      };

      new Exporter(
        // exporterFields,
        exporterFieldsFn(
          formatBatchRecipes(recipes?.rows),
          formatIngredients(ingredients?.rows),
        ),
        i18n('entities.draftLineBuild.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: lineBuildListActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: lineBuildListActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination:
    (pagination) => async (dispatch, getState) => {
      dispatch({
        type: lineBuildListActions.PAGINATION_CHANGED,
        payload: pagination,
      });

      dispatch(lineBuildListActions.doFetchCurrentFilter());
    },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: lineBuildListActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(lineBuildListActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter:
    () => async (dispatch, getState) => {
      const filter = selectors.selectFilter(getState());
      const rawFilter = selectors.selectRawFilter(
        getState(),
      );
      dispatch(
        lineBuildListActions.doFetch(
          filter,
          rawFilter,
          true,
        ),
      );
    },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: lineBuildListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        });

        const response = await LineBuildService.list(
          {
            ...filter, 
            forTable: true, 
          },
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        );

        dispatch({
          type: lineBuildListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        });
      } catch (error) {
        Errors.handle(error);

        dispatch({
          type: lineBuildListActions.FETCH_ERROR,
        });
      }
    },
};

export default lineBuildListActions;
