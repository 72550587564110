import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'ingredients',
    label: i18n('entities.bpIngredient.fields.ingredients'),
    schema: schemas.relationToOne(
      i18n('entities.bpIngredient.fields.ingredients'),
      {},
    ),
  },
];
