import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import conversionUnitEnumerators from 'src/modules/conversionUnit/conversionUnitEnumerators';

export default [
  {
    name: 'unitName',
    label: i18n('entities.conversionUnit.fields.unitName'),
    schema: schemas.string(
      i18n('entities.conversionUnit.fields.unitName'),
      {},
    ),
  },
  {
    name: 'baseUnit',
    label: i18n('entities.conversionUnit.fields.baseUnit'),
    schema: schemas.enumerator(
      i18n('entities.conversionUnit.fields.baseUnit'),
      {
        options: conversionUnitEnumerators.baseUnit,
      },
    ),
  },
  {
    name: 'conversionFactor',
    label: i18n(
      'entities.conversionUnit.fields.conversionFactor',
    ),
    schema: schemas.decimal(
      i18n(
        'entities.conversionUnit.fields.conversionFactor',
      ),
      {},
    ),
  },
];
